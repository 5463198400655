import React, { useEffect, useState } from "react"
import ls from "local-storage"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import styled from "styled-components"
import { Link } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import BannerImage from "../../ui/components/BannerImage/BannerImage"
import ProfileInfoBlock from "../../ui/components/ProfileInfoBlock/ProfileInfoBlock"
import ProfileDescriptionBlock from "../../ui/components/ProfileDescriptionBlock/ProfileDescriptionBlock"
import HR from "../../ui/components/HR/HR"
import MaxWidthContent, {
  ResponsiveMaxWidthContent,
} from "../../ui/components/MaxWidthContent/MaxWidthContent"
import ActionButton, {
  ActionButtonTheme,
} from "../../ui/buttons/ActionButton/ActionButton"
import VideoPreview from "../../ui/components/VideoPreview/VideoPreview"
import theme from "../../ui/theme"
import { ResponsiveMediumPlusHeading } from "../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import SessionsList from "../../ui/components/SessionsList/SessionsList"
import TopicsList from "../../ui/components/TopicsList/TopicsList"
import CoachFurtherDetailsSection from "./components/CoachFurtherDetailsSection/CoachFurtherDetailsSection"
import {
  ICMSCoach,
  getCoachAvatarImage,
  getCoachFoci,
  getCoachFociTitle,
  getCoachName,
  getCoachProfileImage,
  getCoachSynopsis,
  getCoachSynopsisTitle,
  getCoachTags,
  getCoachTopics,
  getCoachTrailerThumbnail,
  getCoachTrailerUrl,
  getCoachUsername,
  getCoachBio,
  getCoachPossessiveName,
  getCoachSlug,
  getCoachSubscriptionPriceString,
  getCoachDesktopIntroImage,
  getCoachFaqs,
  isPlaceholder, coachHasValidTrailer,
} from "../../data/cms/coach"
import { ROUTES } from "../../navigation/routes"
import { getTopicVideos } from "../../firebase/video"
import SiteLayout from "../../components/SiteLayout/SiteLayout"
import SiteWrapper from "../../components/SiteWrapper/SiteWrapper"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../ui/responsive"
import DesktopIntroSection, {
  SignUpWrapper,
} from "./components/DesktopIntroSection/DesktopIntroSection"
import DesktopTopicsSection from "./components/DesktopTopicsSection/DesktopTopicsSection"
import { useIsCoachSubscribedTo } from "../../auth/components/AuthHandler/hooks"
import { useCoachVideos } from "../../firebase/coach"
import { useAuthHandlerContext } from "../../auth/components/AuthHandler/AuthHandler"
import {
  mixpanelEventViewCoach,
  mixpanelEventWatchTrailer,
} from "../../analytics/mixpanel"
import { gaEventVideosWatchTrailer } from "../../analytics/google"
import PageLocation from "../../analytics/components/PageLocation/PageLocation"
import { BackIcon } from "../AccountScreen/views/AccountSettingsView/AccountSettingsView"
import PriceText from "../../ui/components/PriceText/PriceText"
import HubSpotFormModal from "../../components/HubSpotFormModal/HubSpotFormModal"
import VideoOverlay from "../../ui/components/VideoOverlay/VideoOverlay"
import PlayIcon from "../../assets/inline-assets/play.svg"
import AboutYouspired from "../../components/AboutYouspired/AboutYouspired"

const StyledTrailerSection = styled(MaxWidthContent)`
  margin-top: ${theme.spacing.mediumPlusLess}px;
  margin-bottom: ${theme.spacing.mediumPlusLess}px;
`

const StyledSessionsSection = styled.section`
  margin-top: ${theme.spacing.mediumPlusLess}px;
  margin-bottom: ${theme.spacing.mediumPlusLess}px;

  ${DESKTOP_BREAKPOINT} {
    margin-top: 88px;
    margin-bottom: 95px;
  }
`

const StyledSessionsSectionHeadingWrapper = styled(ResponsiveMaxWidthContent)`
  margin-bottom: 16px;

  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 20px;
  }
`

const StyledTopicsSectionHeadingWrapper = styled.div`
  ${MOBILE_BREAKPOINT} {
    padding-bottom: 12px;
    border-bottom: 1px solid ${theme.colors.nearWhite};
    margin-bottom: 26px;
  }

  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 20px;
  }
`

const BannerWrapper = styled.div`
  position: relative;
`

const StyledMobileBackButton = styled.button`
  display: inline-flex;
  padding: 6px 7px 6px 6px;
  position: absolute;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  top: 21px;
  left: 15px;

  svg {
    path {
      stroke: ${theme.colors.white};
    }
  }
`

const SpacingActionButton = styled(ActionButton)`
  margin-bottom: 16px;
`

const StyledActionButton = styled(ActionButton)`
  position: absolute;
  bottom: 0;
  display: inline-flex;
  width: auto;
  padding: 8px 20px 8px 12px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
  align-items: center;
  background: rgb(40 40 40 / 0.6);
  font-weight: ${theme.fontWeights.bold};
  border-radius: ${theme.borderRadius.largePlus}px;
  font-size: ${theme.fontSizes.default}px;

  svg {
    margin-right: 6px;
  }
`

// const StyledOptionWrapper = styled.div`
//   &:not(:first-child) {
//     margin-top: ${theme.spacing.small}px;
//   }
// `

const KEY_COACH_ENTRY_POINT = "COACH_ENTRY_POINT"

const getEntryPointKey = (coachId: string): string => {
  return `${KEY_COACH_ENTRY_POINT}::${coachId}`
}

export enum COACH_ENTRY_POINTS {
  Discover = "Discover",
  Shortlist = "Shortlist",
  Subscriptions = "Subscriptions",
  Home = "Home",
  DirectLink = "Direct Link",
}

export const setCoachEntryPoint = (coachId: string, entryPoint: string) => {
  ls.set(getEntryPointKey(coachId), entryPoint)
}

const getCoachEntryPoint = (coachId: string): string => {
  const entryPoint =
    ls.get(`${KEY_COACH_ENTRY_POINT}::${coachId}`) ||
    COACH_ENTRY_POINTS.DirectLink
  ls.remove(getEntryPointKey(coachId))
  return entryPoint
}

interface Props extends RouteComponentProps {
  coach: ICMSCoach
  coachId: string
  applyToCoachUrl: string
}

const CoachScreen: React.FC<Props> = ({ coach, coachId, applyToCoachUrl }) => {
  const mixpanel = useMixpanel()
  const { authenticated } = useAuthHandlerContext()
  // const isShortlisted = useIsCoachShortlisted(coachId)
  const [isSubscribed] = useIsCoachSubscribedTo(coachId)
  const [vods, loadingVods] = useCoachVideos(coachId)
  const coachName = getCoachName(coach)
  const coachSlug = getCoachSlug(coach)
  const coachPrice = getCoachSubscriptionPriceString(coach)
  const trailerUrl = getCoachTrailerUrl(coach)
  const isCoachPlaceholder = isPlaceholder(coach)
  const hasValidTrailer = coachHasValidTrailer(coach)

  useEffect(() => {
    // use timeout to delay event to ensure that page event has been fired already
    setTimeout(() => {
      mixpanelEventViewCoach(
        mixpanel,
        getCoachName(coach),
        getCoachEntryPoint(coach.coachId),
        isSubscribed
      )
    }, 100)
  }, [])

  const onWatchTrailer = () => {
    mixpanelEventWatchTrailer(mixpanel, coachName, isSubscribed, trailerUrl)
    gaEventVideosWatchTrailer(coachName, 0)
  }

  const topics = getCoachTopics(coach)

  const filteredTopics = loadingVods
    ? topics
    : topics.filter(topic => {
        return getTopicVideos(topic, vods).length > 0
      })

  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const [playVideo, setPlayVideo] = useState(false)

  const openVideoModal = () => {
    setPlayVideo(true)
  }

  return (
    <>
      <section>
        <StyledMobileOnly>
          <header>
            <StyledMobileOnly>
              <BannerWrapper>
                <StyledMobileBackButton as={Link} to={ROUTES.discover.path}>
                  <BackIcon />
                </StyledMobileBackButton>
                <BannerImage
                  image={getCoachProfileImage(coach)}
                  image2x={getCoachProfileImage(coach)}
                />
                {!isCoachPlaceholder && hasValidTrailer && (
                  <StyledActionButton
                    theme={ActionButtonTheme.DARK}
                    onClick={() => {
                      onWatchTrailer()
                      setPlayVideo(true)
                    }}
                  >
                    <PlayIcon /> Play Trailer
                  </StyledActionButton>
                )}
              </BannerWrapper>
            </StyledMobileOnly>
            <ProfileInfoBlock
              profileImage={getCoachAvatarImage(coach)}
              username={getCoachUsername(coach)}
              tags={getCoachTags(coach)}
            />
            <ProfileDescriptionBlock
              name={getCoachName(coach)}
              bio={getCoachBio(coach)}
            />
          </header>
        </StyledMobileOnly>
        <StyledDesktopOnly>
          <DesktopIntroSection
            name={getCoachName(coach)}
            tags={getCoachTags(coach).join(", ")}
            bio={getCoachBio(coach)}
            coachId={coachId}
            coachSlug={coachSlug}
            isSubscribed={isSubscribed}
            isPlaceholder={isCoachPlaceholder}
            subscriptionPrice={getCoachSubscriptionPriceString(coach)}
            introImage={getCoachDesktopIntroImage(coach)}
            applyToCoachUrl={applyToCoachUrl}
            openModal={openModal}
            openVideoModal={openVideoModal}
            hasValidTrailer={hasValidTrailer}
          />
        </StyledDesktopOnly>
        <StyledMobileOnly>
          <MaxWidthContent>
            <HR />
          </MaxWidthContent>
          <MaxWidthContent as="section">
            {isCoachPlaceholder && (
              <SpacingActionButton
                theme={ActionButtonTheme.DARK}
                onClick={() => {
                  openModal()
                }}
              >
                Notify me on launch
              </SpacingActionButton>
            )}
            {isSubscribed && !isCoachPlaceholder && (
              <SpacingActionButton
                as={Link}
                to={ROUTES.accountSubscriptions.path}
              >
                Go to My Coaches
              </SpacingActionButton>
            )}
            {!isSubscribed && !isCoachPlaceholder && (
              <SignUpWrapper isMobile>
                <SpacingActionButton
                  as={Link}
                  to={
                    authenticated
                      ? ROUTES.coachSignupBilling.paramPath(
                          coachSlug,
                          ROUTES.coach.paramPath(coachSlug)
                        )
                      : ROUTES.coachSignup.paramPath(
                          coachSlug,
                          ROUTES.coach.paramPath(coachSlug)
                        )
                  }
                >
                  <PriceText
                    price={Number(getCoachSubscriptionPriceString(coach))}
                    prefix="Subscribe - $"
                    suffix=" USD / month"
                  />
                </SpacingActionButton>
                <p>*GST will be added for Aus residents</p>
              </SignUpWrapper>
            )}
            {!isSubscribed && isCoachPlaceholder && (
              <SpacingActionButton
                theme={ActionButtonTheme.DEFAULT}
                as="a"
                href={applyToCoachUrl}
                target="_blank"
              >
                Apply to coach
              </SpacingActionButton>
            )}
          </MaxWidthContent>
          {!isCoachPlaceholder && hasValidTrailer && (
            <StyledTrailerSection forwardedAs="section">
              <VideoPreview
                thumbnail={getCoachTrailerThumbnail(coach)}
                videoUrl={trailerUrl}
                onWatch={onWatchTrailer}
              />
            </StyledTrailerSection>
          )}
        </StyledMobileOnly>
        {!isCoachPlaceholder && (
          <StyledSessionsSection>
            <StyledSessionsSectionHeadingWrapper>
              <ResponsiveMediumPlusHeading>
                Live Sessions
              </ResponsiveMediumPlusHeading>
            </StyledSessionsSectionHeadingWrapper>
            <SessionsList
              videos={vods}
              loading={loadingVods}
              subscriptionRequired
              isSubscribed={isSubscribed}
              isAuthenticated={authenticated}
              coachName={getCoachPossessiveName(coach)}
              coachPrice={getCoachSubscriptionPriceString(coach)}
              coachSlug={coachSlug}
              showPreviousDays={7}
            />
          </StyledSessionsSection>
        )}
        {(loadingVods || filteredTopics.length > 0) && !isCoachPlaceholder && (
          <ResponsiveMaxWidthContent as="section">
            <StyledTopicsSectionHeadingWrapper>
              <ResponsiveMediumPlusHeading>Topics</ResponsiveMediumPlusHeading>
            </StyledTopicsSectionHeadingWrapper>
            <StyledMobileOnly>
              <TopicsList
                coachId={coachId}
                coachSlug={coachSlug}
                coachPrice={coachPrice}
                coachName={getCoachPossessiveName(coach)}
                topics={filteredTopics}
                videos={vods}
                loading={loadingVods}
                isSubscribed={isSubscribed}
              />
            </StyledMobileOnly>
          </ResponsiveMaxWidthContent>
        )}
        {!isCoachPlaceholder && (
          <StyledDesktopOnly>
            <DesktopTopicsSection
              coachSlug={coachSlug}
              topics={filteredTopics}
              videos={vods}
              loading={loadingVods}
              isSubscribed={isSubscribed}
              isAuthenticated={authenticated}
              coachName={getCoachPossessiveName(coach)}
            />
          </StyledDesktopOnly>
        )}
        <AboutYouspired includeCopy={false} isCompact={true}/>
      </section>
      {playVideo && (
        <VideoOverlay
          videoUrl={trailerUrl}
          close={() => {
            setPlayVideo(false)
          }}
        />
      )}
      <HubSpotFormModal
        opaque={false}
        disableClose={false}
        isOpen={modalIsOpen}
        onClose={() => closeModal()}
        portalId="6886385"
        formId="b199cc21-f08c-4cdf-aa75-3276c76a979e"
      />
    </>
  )
}

const Wrapper: React.FC<Props> = props => (
  <SiteWrapper>
    <SiteLayout>
      <PageLocation pageName={ROUTES.coach.name}>
        <CoachScreen {...props} />
      </PageLocation>
    </SiteLayout>
  </SiteWrapper>
)

export default Wrapper
