import React from "react"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import styled, { css } from "styled-components"
import { Formik } from "formik"
import { navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import ActionButton, {
  ActionButtonTheme,
  ButtonLoadingIndicator,
} from "../../../../../../ui/buttons/ActionButton/ActionButton"
import theme from "../../../../../../ui/theme"
import { placeholderCss } from "../../../../../../ui/helpers"
import { usePaymentFormSchema } from "./validation"
import { confirmPayment } from "../../../../../../api/payment/payment"
import { useFirebaseUser } from "../../../../../../auth/components/AuthHandler/AuthHandler"
import { useSignUpBasePath } from "../../../../components/SignUpContextHandler/SignUpContextHandler"
import { ROUTES } from "../../../../../../navigation/routes"
import { mixpanelEventAddSubscription } from "../../../../../../analytics/mixpanel"
import { gaEventSubscriptionsSubscribe } from "../../../../../../analytics/google"

export const StyledFormContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 36px;
`

export const StyledFormTitle = styled.h6`
  margin-top: 30px;
  margin-bottom: 0px;
  font-weight: ${theme.fontWeights.bold};
`

export const InputWrapper = styled.div`
  margin-top: 15.5px;
`

const DualInputsWrapper = styled(InputWrapper)`
  display: flex;

  > div {
    flex: 1;

    &:first-child {
      padding-right: 11.75px;
    }

    &:last-child {
      padding-left: 11.75px;
    }
  }
`

export const inputCss = css`
  border: 1px solid ${theme.colors.lightGrey};
  padding: 9px 16.5px;
  border-radius: 8px;
`

export const invalidInputCss = css`
  border-color: ${theme.colors.primary};
`

export const Input = styled.input<{
  invalid?: boolean
}>`
  ${inputCss};
  color: ${theme.colors.white};
  background: none;
  line-height: 1.2em;
  display: block;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    outline: none;
    border-color: ${theme.colors.secondary};
  }

  ${placeholderCss(`
    color: ${theme.colors.lightGrey};
    opacity: 1;
  `)}

  ${props => (props.invalid ? invalidInputCss : "")};
`

export const StyledContainer = styled.div`
  .StripeElement {
    ${inputCss};
  }

  .StripeElement--focus {
    border-color: ${theme.colors.secondary};
  }

  .StripeElement--invalid {
    ${invalidInputCss};
  }
`

const ELEMENT_OPTIONS = {
  style: {
    base: {
      color: theme.colors.white,
      fontWeight: "400",
      fontFamily: `${theme.fonts.publicSans}, sans-serif`,
      fontSize: `${theme.fontSizes.default}px`,
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: theme.colors.lightGrey,
      },
    },
    invalid: {
      color: theme.colors.white,
    },
  },
}

const Form: React.FC<{
  coachPrice: number
  coachName: string
  coachId: string
  handleError: (error: any) => void
}> = ({ coachPrice, coachName, coachId, handleError }) => {
  const mixpanel = useMixpanel()
  const stripe = useStripe()
  const elements = useElements()
  const validationSchema = usePaymentFormSchema()
  const fbUser = useFirebaseUser()
  const basePath = useSignUpBasePath()

  const onSubmit = async (
    { name }: { name: string },
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: {
      setErrors: (arg0: any) => void
      setStatus: (arg0: any) => void
      setSubmitting: (arg0: boolean) => void
    }
  ): Promise<any> => {
    if (!stripe || !elements || !fbUser) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return Promise.reject()
    }

    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCVCElement = elements.getElement(CardCvcElement)

    // console.log(
    //   "submitting?",
    //   name,
    //   cardNumberElement,
    //   cardExpiryElement,
    //   cardCVCElement
    // )

    if (!cardNumberElement || !cardExpiryElement || !cardCVCElement) {
      console.error(`Stripe elements haven't loaded properly.`)
      setSubmitting(false)
      setStatus({
        stripe: "Something went wrong, please try again.",
      })
      return Promise.reject()
    }

    return confirmPayment(stripe, cardNumberElement, fbUser, coachId, name)
      .then((nextBillingDate: string) => {
        mixpanelEventAddSubscription(mixpanel, coachName)
        gaEventSubscriptionsSubscribe(coachName, coachPrice)
        navigate(
          `${basePath}${ROUTES.signUpCompleted.subPath}?d=${nextBillingDate}`
        )
      })
      .catch(error => {
        console.error(error)
        Sentry.captureMessage("Something went wrong when confirming payment")
        Sentry.captureException(error)
        setSubmitting(false)
        handleError(error)
      })
  }

  return (
    <StyledContainer>
      <StyledFormTitle>Credit Card Details</StyledFormTitle>
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => {
          const submitDisabled = !stripe || !isValid || isSubmitting
          return (
            <form onSubmit={handleSubmit}>
              <StyledFormContainer>
                <InputWrapper>
                  <Input
                    name="name"
                    invalid={touched.name && !!errors.name}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name on Card"
                    type="text"
                  />
                </InputWrapper>
                <InputWrapper>
                  <CardNumberElement
                    options={{
                      ...ELEMENT_OPTIONS,
                      placeholder: "Card Number",
                    }}
                  />
                </InputWrapper>
                <DualInputsWrapper>
                  <div>
                    <CardExpiryElement options={ELEMENT_OPTIONS} />
                  </div>
                  <div>
                    <CardCvcElement
                      options={{
                        ...ELEMENT_OPTIONS,
                        placeholder: "CVC",
                      }}
                    />
                  </div>
                </DualInputsWrapper>
              </StyledFormContainer>
              <div>
                <ActionButton
                  theme={ActionButtonTheme.SECONDARY}
                  type="submit"
                  disabled={submitDisabled}
                  loading={isSubmitting}
                >
                  Confirm Payment
                  {isSubmitting && <ButtonLoadingIndicator />}
                </ActionButton>
              </div>
            </form>
          )
        }}
      </Formik>
    </StyledContainer>
  )
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY || "")

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Public+Sans:400",
    },
  ],
}

const PaymentForm: React.FC<{
  handleError: (error: any) => void
  coachId: string
  coachName: string
  coachPrice: number
}> = ({ coachId, coachName, coachPrice, handleError }) => (
  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
    <Form
      coachId={coachId}
      coachName={coachName}
      coachPrice={coachPrice}
      handleError={handleError}
    />
  </Elements>
)

export default PaymentForm
