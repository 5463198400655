import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import moment from "moment"
import theme from "../../../../../../../ui/theme"
import AppleLogoButton from "../../../../../../../assets/inline-assets/appleLogoButton.svg"
import DiscoverIcon from "../../../../../../../assets/inline-assets/discover.svg"
import { SUBSCRIPTION_STORES } from "./data"
import PriceText from "../../../../../../../ui/components/PriceText/PriceText"

const SubscriptionStatusDate = styled("p")`
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 18px;
  margin-top: 10px;

  &.web {
    margin-top: 4px;
  }
`

const SubscriptionSource = styled("p")`
  line-height: 18px;
  margin-top: 12px;
  font-size: ${theme.fontSizes.small}px;
  font-weight: ${theme.fontWeights.semiBold};
  color: ${theme.colors.lightGrey};
`

export const StyledActionButton = styled.button`
  padding: 12px 10px;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small}px;
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish}px;
`

export const StyledActionSvgButton = styled(StyledActionButton)`
  width: 220px;
  margin-top: 4px;
  display: flex;
  justify-content;
  display: flex;
  jusitfy-content: center;
  align-items: center;
  padding: 4px 10px;
  text-align: center;

  svg {
    margin-right: 10px;
    transform: scale(0.8);
  }
`

const StyledCancelButton = styled.button`
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: inherit;
`

const StyledRenewButton = styled(StyledActionSvgButton)`
  padding: 13px 10px 13px 50px;
  background-color: #b3b1de;
`

const StyledManageSubscriptionButton = styled(StyledActionSvgButton)`
  background-color: ${theme.colors.white};
  color: ${theme.colors.darkGrey};
  border: 2px solid ${theme.colors.lightGrey};
  display: flex;
  jusitfy-content: center;
  align-items: center;
  padding: 4px 10px;
  text-align: center;

  svg {
    path {
      fill: #404040;
    }
  }
`

const SubscriptionPrice = styled("p")`
  font-weight: ${theme.fontWeights.medium};
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 18px;

  &.web {
    margin-top: 10px;
  }
`

export const formatDate = (timestamp: string): string => {
  try {
    const dateString = moment(timestamp).format("DD MMM YYYY")
    return dateString
  } catch (error) {
    return timestamp
  }
}

const SubscriptionAction: React.FC<{
  store: string
  cancelledState: boolean
  isExpired: boolean
  nextDate: string
  amount: number
  setShowCancelPrompt: (openState: boolean) => void
  setShowRenewPrompt: (openState: boolean) => void
  to: string
}> = ({
  store,
  cancelledState,
  isExpired,
  nextDate,
  amount,
  setShowCancelPrompt,
  setShowRenewPrompt,
  to,
}) => {
  return (
    <>
      {store !== SUBSCRIPTION_STORES.STRIPE ? (
        <>
          {cancelledState ? (
            <>
              <SubscriptionStatusDate>
                Subscription expires: {formatDate(nextDate)}
              </SubscriptionStatusDate>
            </>
          ) : (
            <>
              <SubscriptionStatusDate>
                Next billing date: {formatDate(nextDate)}
              </SubscriptionStatusDate>
            </>
          )}
          <SubscriptionSource>Subscribed via app</SubscriptionSource>
          <StyledManageSubscriptionButton>
            <AppleLogoButton />
            Manage Subscription
          </StyledManageSubscriptionButton>
        </>
      ) : (
        <>
          <SubscriptionPrice className="web">
            <PriceText
              price={Number(amount)}
              prefix="$"
              suffix=" USD / month"
            />
          </SubscriptionPrice>
          {cancelledState ? (
            <>
              <SubscriptionStatusDate className="web">
                Subscription expires: {formatDate(nextDate)}
              </SubscriptionStatusDate>
              <SubscriptionSource>Subscribed via web</SubscriptionSource>
              {!isExpired ? (
                <StyledRenewButton
                  as={Link}
                  to={to}
                  onClick={() => {
                    setShowRenewPrompt(true)
                  }}
                >
                  Renew Subscription
                </StyledRenewButton>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <SubscriptionStatusDate className="web">
                Next billing date: {formatDate(nextDate)}
              </SubscriptionStatusDate>
              <SubscriptionSource>Subscribed via web</SubscriptionSource>
              <StyledCancelButton
                onClick={() => {
                  setShowCancelPrompt(true)
                }}
              >
                Cancel Subscription
              </StyledCancelButton>
            </>
          )}
        </>
      )}
    </>
  )
}

export default SubscriptionAction
