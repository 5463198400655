import { graphql } from "gatsby"
import { get } from "lodash"
import gql from "graphql-tag"
import { ICMSSeoTags } from "./socialMeta"

export interface ICMSCoach {
  id: string
  slug: string
  coachId: string
  username: string
  avatar: {
    url: string
  } | null
  profilePicture: {
    url: string
  } | null
  trailerThumbnail: {
    url: string
  } | null
  trailer: {
    url: string
  }
  name: string
  possessiveName: string
  bio: string
  tags: string
  topics: string
  synopsisTitle: string
  synopsis: string
  fociTitle: string
  foci: string
  charityName: string
  charityPercentage: number | null
  liveStreamId: string
  seoMetaTags: {
    tags: {
      tagName: string
      content?: string
      attributes?: {
        property?: string
        name?: string
        content: string
      }
    }[]
  }
  subscriptionPrice?: number | null
  websiteDesktopIntroImage?: {
    url: string
  }
  websiteDesktopIntroImage2x?: {
    url: string
  }
  faqs: {
    question: string
    answer: string
  }[]
  websiteMeta?: ICMSSeoTags
}

export const getCoachWebsiteMeta = (coach: ICMSCoach): ICMSSeoTags | null => {
  return get(coach, "websiteMeta", null) || null
}

export const getCoachFaqs = (coach: ICMSCoach): ICMSCoach["faqs"] => {
  const faqs: ICMSCoach["faqs"] = get(coach, "faqs", []) || []
  return faqs
}

export const getCoachDesktopIntroImage = (
  coach: ICMSCoach
): [string, string] => {
  const image = get(coach, "websiteDesktopIntroImage.url", "") || ""
  const image2x = get(coach, "websiteDesktopIntroImage2x.url", "") || ""
  return [image, image2x]
}

export const getCoachSubscriptionPrice = (coach: ICMSCoach): number => {
  const price = get(coach, "subscriptionPrice", 0) || 0
  if (price) {
    return price
  }
  return 0
}

export const getCoachSubscriptionPriceString = (coach: ICMSCoach): number => {
  const price = getCoachSubscriptionPrice(coach)
  if (price) {
    return price / 100
  }
  return 0
}

export const getCoachSlug = (coach: ICMSCoach): string => {
  return get(coach, "slug", "") || ""
}

export const getCoachSeoMetaTags = (coach: ICMSCoach): any => {
  return get(coach, "seoMetaTags", null) || null
}

export const getCoachUsername = (coach: ICMSCoach): string => {
  return get(coach, "username", "") || ""
}

export const getCoachName = (coach: ICMSCoach): string => {
  return get(coach, "name", "") || ""
}

export const getCoachPossessiveName = (coach: ICMSCoach): string => {
  return get(coach, "possessiveName", "") || ""
}

export const getCoachBio = (coach: ICMSCoach): string => {
  return get(coach, "bio", "") || ""
}

export const getCoachAvatarImage = (coach: ICMSCoach): string => {
  return get(coach, "avatar.url", "") || ""
}

export const getCoachProfileImage = (coach: ICMSCoach): string => {
  return get(coach, "profilePicture.url", "") || ""
}

export const getCoachTrailerThumbnail = (coach: ICMSCoach): string => {
  return get(coach, "trailerThumbnail.url", "") || ""
}

export const getCoachTrailerUrl = (coach: ICMSCoach): string => {
  return get(coach, "trailer.url", "") || ""
}

export const getCoachSynopsisTitle = (coach: ICMSCoach): string => {
  return get(coach, "synopsisTitle", "") || ""
}

export const getCoachSynopsis = (coach: ICMSCoach): string => {
  return get(coach, "synopsis", "") || ""
}

export const getCoachFociTitle = (coach: ICMSCoach): string => {
  return get(coach, "fociTitle", "") || ""
}

export const getCoachFoci = (coach: ICMSCoach): string[] => {
  const foci = get(coach, "foci", "") || ""
  return foci.split("¶")
}

export const getCoachTags = (coach: ICMSCoach): string[] => {
  const tags = get(coach, "tags", "") || ""
  return tags.split("¶")
}

export const getCoachTopics = (coach: ICMSCoach): string[] => {
  const topics = get(coach, "topics", "") || ""
  return topics.split("¶")
}

export const isPlaceholder = (coach: ICMSCoach): boolean => {
  return get(coach, "placeholder", false) || false
}

export const coachHasValidTrailer = (coach: ICMSCoach): boolean => {
  const trailerUrl = getCoachTrailerUrl(coach)
  const trailerThumbnail = getCoachTrailerThumbnail(coach)
  return !!trailerUrl && !!trailerThumbnail
}

export const query = graphql`
  fragment PrdCoach on DatoCmsCoachPrd {
    id
    slug
    coachId
    username
    avatar {
      url
    }
    profilePicture {
      url
    }
    trailerThumbnail {
      url
    }
    trailer {
      url
    }
    name
    possessiveName
    bio
    tags
    topics
    synopsisTitle
    synopsis
    fociTitle
    foci
    charityName
    charityPercentage
    liveStreamId
    placeholder
    seoMetaTags {
      tags
    }
    subscriptionPrice
    websiteDesktopIntroImage {
      url
    }
    websiteDesktopIntroImage2x {
      url
    }
    faqs {
      question
      answer
    }
    websiteMeta {
      ...SeoTags
    }
  }
  fragment UatCoach on DatoCmsCoachUat {
    id
    slug
    coachId
    username
    avatar {
      url
    }
    profilePicture {
      url
    }
    trailerThumbnail {
      url
    }
    trailer {
      url
    }
    name
    possessiveName
    bio
    tags
    topics
    synopsisTitle
    synopsis
    fociTitle
    foci
    charityName
    charityPercentage
    liveStreamId
    placeholder
    seoMetaTags {
      tags
    }
    subscriptionPrice
    websiteDesktopIntroImage {
      url
    }
    websiteDesktopIntroImage2x {
      url
    }
    faqs {
      question
      answer
    }
    websiteMeta {
      ...SeoTags
    }
  }
  fragment Coach on DatoCmsCoachStg {
    id
    slug
    coachId
    username
    avatar {
      url
    }
    profilePicture {
      url
    }
    trailerThumbnail {
      url
    }
    trailer {
      url
    }
    name
    possessiveName
    bio
    tags
    topics
    synopsisTitle
    synopsis
    fociTitle
    foci
    charityName
    charityPercentage
    liveStreamId
    placeholder
    seoMetaTags {
      tags
    }
    subscriptionPrice
    websiteDesktopIntroImage {
      url
    }
    websiteDesktopIntroImage2x {
      url
    }
    faqs {
      question
      answer
    }
    websiteMeta {
      ...SeoTags
    }
  }
  fragment DevCoach on DatoCmsCoachDev {
    id
    slug
    coachId
    username
    avatar {
      url
    }
    profilePicture {
      url
    }
    trailerThumbnail {
      url
    }
    trailer {
      url
    }
    name
    possessiveName
    bio
    tags
    topics
    synopsisTitle
    synopsis
    fociTitle
    foci
    charityName
    charityPercentage
    liveStreamId
    placeholder
    seoMetaTags {
      tags
    }
    subscriptionPrice
    websiteDesktopIntroImage {
      url
    }
    websiteDesktopIntroImage2x {
      url
    }
    faqs {
      question
      answer
    }
    websiteMeta {
      ...SeoTags
    }
  }
`

export interface ICMSCoachMin {
  coachId: string
  name: string
  slug: string
  possessiveName: string
  subscriptionPrice?: number | null
}

export const getCoachMinSubscriptionPrice = (data: ICMSCoachMin): number => {
  const price = get(data, "subscriptionPrice", null) || null
  if (price) {
    return price / 100
  }
  return 0
}

export const getCoachMinSlug = (data: ICMSCoachMin): string => {
  return get(data, "slug", "") || ""
}

export const getCoachMinName = (data: ICMSCoachMin): string => {
  return get(data, "name", "") || ""
}

export const getCoachMinPossessiveName = (data: ICMSCoachMin): string => {
  return get(data, "possessiveName", "") || ""
}

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const FETCH_COACH = gql`
  query CoachQuery(
    $coachId: String!
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    coachUat(filter: { coachId: { eq: $coachId } }) @include(if: $uatEnv) {
      coachId
      name
      slug
      possessiveName
      subscriptionPrice
    }
    coachPrd(filter: { coachId: { eq: $coachId } }) @include(if: $prdEnv) {
      coachId
      name
      slug
      possessiveName
      subscriptionPrice
    }
    coachStg(filter: { coachId: { eq: $coachId } }) @include(if: $stgEnv) {
      coachId
      name
      slug
      possessiveName
      subscriptionPrice
    }
    coachDev(filter: { coachId: { eq: $coachId } }) @include(if: $devEnv) {
      coachId
      name
      slug
      possessiveName
      subscriptionPrice
    }
  }
`
