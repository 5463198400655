import React from "react"
import styled from "styled-components"
import Avatar from "../Avatar/Avatar"
import Grid from "../Grid/Grid"
import GridCol from "../GridCol/GridCol"
import MaxWidthContent from "../MaxWidthContent/MaxWidthContent"
import theme from "../../theme"
import MediumPlusHeading from "../../typography/MediumPlusHeading/MediumPlusHeading"
import SmallishHeading from "../../typography/SmallishHeading/SmallishHeading"

const StyledWrapper = styled(MaxWidthContent)`
  margin-top: ${theme.spacing.mediumPlus}px;
`

const StyledContainer = styled(Grid)`
  align-items: center;
`

const StyledListHeading = styled(SmallishHeading)`
  margin-top: 4px;
`

interface Props {
  profileImage: string
  username: string
  tags: string[]
}

const ProfileInfoBlock: React.FC<Props> = ({
  profileImage,
  username,
  tags,
}) => (
  <StyledWrapper forwardedAs="section">
    <StyledContainer>
      <GridCol columns={1}>
        <Avatar image={profileImage} />
      </GridCol>
      <GridCol columns={3}>
        <MediumPlusHeading as="h2">{username}</MediumPlusHeading>
        <StyledListHeading>{tags.join(", ")}</StyledListHeading>
      </GridCol>
    </StyledContainer>
  </StyledWrapper>
)

export default ProfileInfoBlock
