import React from "react"
import styled from "styled-components"
import { Router } from "@reach/router"
import theme from "../../ui/theme"
import { ROUTES } from "../../navigation/routes"
import EmailSignUpView from "./views/CreateStep/views/EmailSignUpView/EmailSignUpView"
import SelectSignUpOptionView from "./views/CreateStep/views/SelectSignUpOptionView/SelectSignUpOptionView"
import BillingStep from "./views/BillingStep/BillingStep"
import PaymentStep from "./views/PaymentStep/PaymentStep"
import CompletedStep from "./views/CompletedStep/CompletedStep"
import SignUpContextHandler from "./components/SignUpContextHandler/SignUpContextHandler"
import { ICMSCoach } from "../../data/cms/coach"
import NoCoachCompletedStep from "./views/NoCoachCompletedStep/NoCoachCompletedStep"
import { DetailsStepWrapper } from "./views/DetailsStep/DetailsStep"
import PageLocation from "../../analytics/components/PageLocation/PageLocation"
import { ICMSSignUpFlow } from "../../data/cms/signUpFlow"

const StyledContainer = styled.div`
  color: ${theme.colors.white};
`

const SignUpScreen: React.FC<{
  basePath: string
  coach?: ICMSCoach
  content: ICMSSignUpFlow
}> = ({ basePath, coach, content }) => {
  const signUpDestination = !coach
    ? ROUTES.signUpCompleted.subPath
    : ROUTES.signUpBilling.subPath
  return (
    <PageLocation pageName={ROUTES.signUp.name}>
      <SignUpContextHandler basePath={basePath} coach={coach}>
        <StyledContainer>
          <Router basepath={basePath}>
            {coach ? (
              <CompletedStep
                coach={coach}
                path={ROUTES.signUpCompleted.subPath}
                content={content}
              />
            ) : (
              <NoCoachCompletedStep path={ROUTES.signUpCompleted.subPath} />
            )}
            {coach && (
              <BillingStep
                path={ROUTES.signUpBilling.subPath}
                coach={coach}
                content={content}
              />
            )}
            {coach && (
              <PaymentStep
                path={ROUTES.signUpPayment.subPath}
                coach={coach}
                content={content}
              />
            )}
            <DetailsStepWrapper
              path={ROUTES.signUpDetails.subPath}
              termsRequired={false}
              signUpDestination={signUpDestination}
              noCoach={!coach}
            />
            <DetailsStepWrapper
              path={ROUTES.signUpAccount.subPath}
              signUpDestination={signUpDestination}
              noCoach={!coach}
            />
            <EmailSignUpView
              path={ROUTES.signUpCreateEmail.subPath}
              noCoach={!coach}
            />
            <SelectSignUpOptionView path={ROUTES.signUpCreate.subPath} />
            <SelectSignUpOptionView
              path={ROUTES.signUp.subPath}
              firstStep
              noCoach={!coach}
              coachSlug={coach ? coach.slug : ""}
            />
          </Router>
        </StyledContainer>
      </SignUpContextHandler>
    </PageLocation>
  )
}

export default SignUpScreen
