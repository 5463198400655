import React, { useEffect } from "react"
import { useUserInfo } from "../../../../../../auth/components/AuthHandler/hooks"

const ProfileImageHelper: React.FC<{
  setHasImage: () => void
}> = ({ setHasImage }) => {
  const { image } = useUserInfo()
  useEffect(() => {
    if (image) {
      setHasImage()
    }
  }, [image])
  return null
}

export default ProfileImageHelper
