import styled from "styled-components"
import theme from "../../theme"

const SmallishHeading = styled.h3`
  color: ${theme.colors.lightGrey};
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish / theme.fontSizes.smallish};
`

export default SmallishHeading
