import React from "react"
import { RouteComponentProps } from "@reach/router"
import SignUpScreen from "../SignUpScreen/SignUpScreen"
import { ROUTES } from "../../navigation/routes"
import SiteLayout from "../../components/SiteLayout/SiteLayout"
import SiteWrapper from "../../components/SiteWrapper/SiteWrapper"
import { getCoachSlug, ICMSCoach } from "../../data/cms/coach"
import { ICMSSignUpFlow } from "../../data/cms/signUpFlow"

interface Props extends RouteComponentProps {
  coachId: string
  coach: ICMSCoach
  content: ICMSSignUpFlow
}

const UserCoachSignupScreen: React.FC<Props> = ({ coach, content }) => (
  <SiteWrapper darkTheme>
    <SiteLayout hideHeaderOptions>
      <SignUpScreen
        basePath={ROUTES.coachSignup.paramPath(getCoachSlug(coach))}
        coach={coach}
        content={content}
      />
    </SiteLayout>
  </SiteWrapper>
)

export default UserCoachSignupScreen
