import React, { useEffect } from "react"
import { get } from "lodash"
import { RouteComponentProps } from "@reach/router"
import { Formik } from "formik"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { StyledStepHeading } from "../../../InfoStep/InfoStep"
import ActionButton, {
  ActionButtonTheme,
  ButtonLoadingIndicator,
} from "../../../../../../ui/buttons/ActionButton/ActionButton"
import CreateStep from "../../CreateStep"
import { ROUTES } from "../../../../../../navigation/routes"
import ValidatedInput from "../../../../../../ui/inputs/ValidatedInput/ValidatedInput"
import theme from "../../../../../../ui/theme"
import { useEmailSignUpFormSchema } from "./validation"
import { firebaseSignUpWithEmailAndPassword } from "../../../../../../firebase/auth"
import { useSignUpBasePath } from "../../../../components/SignUpContextHandler/SignUpContextHandler"
import { useIsUserAuthenticated } from "../../../../../../auth/components/AuthHandler/AuthHandler"
import TermsAndConditionsCheck from "./components/TermsAndConditionsCheck/TermsAndConditionsCheck"
import { StyledFirebaseError } from "../SelectSignUpOptionView/components/FirebaseErrorMessage/FirebaseErrorMessage"

const StyledForm = styled.form`
  margin-top: 13.5px;
`

const StyledInputWrapper = styled.div`
  &:not(:first-child) {
    margin-top: ${theme.spacing.small}px;
  }
`

export const StyledTermsWrapper = styled.div`
  margin: ${theme.spacing.mediumPlusLess}px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const StyledTermsLabel = styled.label`
  display: flex;
  align-items: center;

  a {
    text-decoration: underline;
  }

  span {
    display: inline-block;
    margin-left: 13.35px;
  }
`

export const handleFirebaseErrors = (
  error: Error,
  setErrors: (arg0: any) => void,
  setStatus: (arg0: any) => void
) => {
  const code = get(error, "code", "")
  if (code === "auth/email-already-in-use") {
    setErrors({
      email: get(error, "message", ""),
    })
  } else {
    setStatus({
      firebaseError: get(error, "message", ""),
    })
  }
}

interface Props extends RouteComponentProps {
  noCoach: boolean
}

const EmailSignUpView: React.FC<Props> = () => {
  const mixpanel = useMixpanel()
  const validationSchema = useEmailSignUpFormSchema()
  const basePath = useSignUpBasePath()
  const authenticated = useIsUserAuthenticated()

  const handleNextStep = () => {
    navigate(`${basePath}${ROUTES.signUpDetails.subPath}`)
  }

  const onSubmit = (
    {
      email,
      password,
    }: {
      email: string
      password: string
      terms: boolean
    },
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: {
      setErrors: (arg0: any) => void
      setStatus: (arg0: any) => void
      setSubmitting: (arg0: boolean) => void
    }
  ) => {
    firebaseSignUpWithEmailAndPassword(email, password, mixpanel)
      .then(() => {
        handleNextStep()
      })
      .catch(error => {
        console.error(error)
        Sentry.captureMessage(
          "Something went wrong when signing up with email and password"
        )
        Sentry.captureException(error)
        handleFirebaseErrors(error, setErrors, setStatus)
        setSubmitting(false)
      })
  }

  useEffect(() => {
    if (authenticated) {
      handleNextStep()
    }
  }, [authenticated])

  return (
    <CreateStep back={`${basePath}${ROUTES.signUp.subPath}`} firstStep>
      <section>
        <StyledStepHeading as="h2">Create Account</StyledStepHeading>
        <Formik
          initialValues={{
            email: "",
            password: "",
            terms: false,
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => {
            const isLoading = isSubmitting
            const submitDisabled = !isValid || isLoading
            return (
              <StyledForm onSubmit={handleSubmit}>
                <div>
                  <StyledInputWrapper>
                    <ValidatedInput
                      name="email"
                      label="Email address"
                      type="email"
                      valid={touched.email && !errors.email}
                      errorMessage={errors.email || ""}
                      invalid={touched.email && !!errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </StyledInputWrapper>
                  <StyledInputWrapper>
                    <ValidatedInput
                      name="password"
                      label="Password"
                      type="password"
                      valid={touched.password && !errors.password}
                      errorMessage={errors.password || ""}
                      invalid={touched.password && !!errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  </StyledInputWrapper>
                </div>
                <TermsAndConditionsCheck
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.terms}
                />
                <div>
                  <ActionButton
                    theme={ActionButtonTheme.SECONDARY}
                    loading={isLoading}
                    disabled={submitDisabled}
                  >
                    Next
                    {isLoading && <ButtonLoadingIndicator />}
                  </ActionButton>
                </div>
                {status && status.firebaseError && (
                  <StyledFirebaseError>
                    {status.firebaseError}
                  </StyledFirebaseError>
                )}
              </StyledForm>
            )
          }}
        </Formik>
      </section>
    </CreateStep>
  )
}

export default EmailSignUpView
