import React from "react"
import Modal from "./components/Modal/Modal"
import useHubspotForm from '@aaronhayes/react-use-hubspot-form';

const HubSpotFormModal: React.FC<{
  disableClose: boolean
  onClose: () => void
  opaque: boolean
  footer?: any
  wider?: boolean
  isOpen?: boolean
  portalId: string
  formId: string
}> = ({ disableClose, onClose, opaque, wider = false, isOpen = false, portalId, formId }) => {
  if (isOpen) {
    useHubspotForm({
      portalId: portalId,
      formId: formId,
      target: '#hubspot-form'
    });
  }

  return (
    <Modal opaque={opaque} disableClose={disableClose} isOpen={isOpen} onClose={onClose} wider={wider}>
      <div id="hubspot-form"></div>
    </Modal>
  )
}

export default HubSpotFormModal
