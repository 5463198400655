import * as Yup from "yup"

export const useSignUpDetailsFormSchema = (
  usernameRequired: boolean,
  termsRequired: boolean
) => {
  const fields: {
    username?: any
    profileImage: any
    terms?: any
  } = {
    profileImage: Yup.bool().oneOf([true]),
  }
  if (usernameRequired) {
    fields.username = Yup.string()
      .matches(/^[a-z0-9_]{3,15}$/g, {
        message:
          "Username must be 15 characters or less and contain only lowercase letters, numbers or underscores.",
      })
      .max(15)
      .required()
  }
  if (termsRequired) {
    fields.terms = Yup.bool().oneOf([true])
  }
  return Yup.object().shape(fields)
}
