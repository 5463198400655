import React, { useState } from "react"
import styled from "styled-components"
import theme from "../../theme"
import { getResponsiveMobileHeight } from "../../helpers"
import PlayIcon from "../../../assets/inline-assets/play.svg"
import VideoOverlay from "../VideoOverlay/VideoOverlay"

const StyledContainer = styled.button<{
  image: string
}>`
  height: 204px;
  height: ${getResponsiveMobileHeight(204)};
  border-radius: ${theme.borderRadius.common}px;
  background: grey;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: ${theme.colors.white};
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});
`

const StyledPlayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  border: 2px solid #ffffff;
`

const StyledLabel = styled.div`
  font-size: ${theme.fontSizes.default}px;
  line-height: ${theme.lineHeights.smallishPlus}px;
  margin-bottom: 28px;
  margin-top: 16px;
`

const VideoPreview: React.FC<{
  thumbnail: string
  videoUrl: string
  onWatch: () => void
}> = ({ thumbnail, videoUrl, onWatch }) => {
  const [playVideo, setPlayVideo] = useState(false)
  return (
    <>
      <StyledContainer
        image={thumbnail}
        type="button"
        onClick={() => {
          onWatch()
          setPlayVideo(true)
        }}
      >
        <StyledPlayButton>
          <PlayIcon />
        </StyledPlayButton>
        <StyledLabel>Watch Trailer</StyledLabel>
      </StyledContainer>
      {playVideo && (
        <VideoOverlay
          videoUrl={videoUrl}
          close={() => {
            setPlayVideo(false)
          }}
        />
      )}
    </>
  )
}

export default VideoPreview
