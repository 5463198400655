import React from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { Link } from "gatsby"
import MaxWidthContent from "../../ui/components/MaxWidthContent/MaxWidthContent"
import { minMobileHeightContentCss } from "../../ui/shared"
import LargeHeading from "../../ui/typography/LargeHeading/LargeHeading"
import BackButton from "../../ui/buttons/BackButton/BackButton"
import { getTopicVideos, IGatsbyVideo } from "../../firebase/video"
import { ROUTES } from "../../navigation/routes"
import SessionPreview, {
  StyledContainer as StyledSessionPreviewContainer,
} from "../../ui/components/SessionsList/components/SessionPreview/SessionPreview"
import CompactFooter from "../../ui/components/CompactFooter/CompactFooter"
import SiteLayout from "../../components/SiteLayout/SiteLayout"
import SiteWrapper from "../../components/SiteWrapper/SiteWrapper"
import { useCoachVideos } from "../../firebase/coach"
import PageLocation from "../../analytics/components/PageLocation/PageLocation"

const StyledContainer = styled(MaxWidthContent)`
  ${minMobileHeightContentCss};
  padding-top: 32px;
`

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 31px;
`

const StyledTitle = styled(LargeHeading)`
  line-height: 30px;
  margin-left: 22px;
`

const StyledVideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -26px;
`

const StyledVideoWrapper = styled.div`
  width: 50%;
  margin-top: 26px;

  &:nth-of-type(odd) {
    padding-right: 12px;
  }

  &:nth-of-type(even) {
    padding-left: 12px;
  }
`

interface Props extends RouteComponentProps {
  coachId: string
  coachSlug: string
  topic: string
}

const Placeholder = () => (
  <>
    <StyledVideoWrapper>
      <StyledSessionPreviewContainer compact />
    </StyledVideoWrapper>
    <StyledVideoWrapper>
      <StyledSessionPreviewContainer compact />
    </StyledVideoWrapper>
    <StyledVideoWrapper>
      <StyledSessionPreviewContainer compact />
    </StyledVideoWrapper>
    <StyledVideoWrapper>
      <StyledSessionPreviewContainer compact />
    </StyledVideoWrapper>
  </>
)

const CoachTopicScreen: React.FC<Props> = ({ coachId, coachSlug, topic }) => {
  const [vods, loadingVods] = useCoachVideos(coachId)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation pageName={ROUTES.coachTopic.name}>
          <>
            <StyledContainer>
              <StyledHeader>
                <div>
                  <BackButton
                    as={Link}
                    to={ROUTES.coach.paramPath(coachSlug)}
                  />
                </div>
                <StyledTitle as="h2">{topic}</StyledTitle>
              </StyledHeader>
              <StyledVideosContainer>
                {loadingVods ? (
                  <Placeholder />
                ) : (
                  getTopicVideos(topic, vods).map((video, index) => {
                    return (
                      <StyledVideoWrapper key={index.toString()}>
                        <SessionPreview compact video={video} />
                      </StyledVideoWrapper>
                    )
                  })
                )}
              </StyledVideosContainer>
            </StyledContainer>
            <CompactFooter />
          </>
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default CoachTopicScreen
