import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ResponsiveScrollableContainer } from "../../../../ui/layout"
import { getTopicVideos, IGatsbyVideo } from "../../../../firebase/video"
import SessionsList from "../../../../ui/components/SessionsList/SessionsList"
import theme from "../../../../ui/theme"
import { placeholderBackgroundCss } from "../../../../ui/shared"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../../../ui/responsive"

const StyledTopicsNav = styled.nav`
  margin-bottom: 16px;

  li {
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
`

export const loadingTextCss = css`
  color: transparent;
  ${placeholderBackgroundCss};
`

const StyledTopicButton = styled.button<{
  loading: boolean
  selected: boolean
}>`
  cursor: pointer;
  text-align: left;
  padding: 0;
  width: auto;
  display: block;
  white-space: nowrap;

  h4 {
    font-size: ${theme.fontSizes.largePlus}px;
    line-height: ${theme.lineHeights.largePlus}px;
    font-weight: 700;
    color: ${props =>
      props.selected ? theme.colors.darkGrey : theme.colors.lightGrey};
  }

  &:hover {
    h4 {
      color: ${theme.colors.darkGrey};
    }
  }

  p {
    color: ${theme.colors.lightGrey};
    font-size: 16px;
    line-height: 27px;

    ${props => (props.loading ? loadingTextCss : "")};
  }
`

const StyledWrapper = styled.div`
    margin-bottom: 128px;
`

const DesktopTopicsSection: React.FC<{
  coachSlug: string
  topics: string[]
  videos: IGatsbyVideo[]
  loading?: boolean
  isSubscribed: boolean
  isAuthenticated: boolean
  coachName: string
}> = ({
  topics,
  videos,
  loading = false,
  isSubscribed,
  isAuthenticated,
  coachName,
  coachSlug,
}) => {
  const [selectedTopic, setSelectedTopic] = useState(
    topics.length > 0 ? topics[0] : ""
  )
  const topicVideos = selectedTopic ? getTopicVideos(selectedTopic, videos) : []
  return (
    <StyledWrapper>
      <StyledTopicsNav>
        <ResponsiveScrollableContainer>
          {topics.map(topic => {
            return (
              <li key={topic}>
                <StyledTopicButton
                  selected={selectedTopic === topic}
                  onClick={() => setSelectedTopic(topic)}
                  loading={loading}
                >
                  <h4>{topic}</h4>
                  <p>{getTopicVideos(topic, videos).length} videos</p>
                </StyledTopicButton>
              </li>
            )
          })}
        </ResponsiveScrollableContainer>
      </StyledTopicsNav>
      <SessionsList
        videos={topicVideos}
        loading={loading}
        subscriptionRequired
        isSubscribed={isSubscribed}
        isAuthenticated={isAuthenticated}
        coachName={coachName}
        coachSlug={coachSlug}
      />
    </StyledWrapper>
  )
}

export default DesktopTopicsSection
