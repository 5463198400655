import React, { useState } from "react"
import { navigate } from "gatsby"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import styled from "styled-components"
import { Formik } from "formik"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import ActionButton, {
  ActionButtonTheme,
  ButtonLoadingIndicator,
} from "../../../../../../ui/buttons/ActionButton/ActionButton"
import theme from "../../../../../../ui/theme"
import { useBillingFormSchema } from "./validation"
import { useSignUpContext } from "../../../../components/SignUpContextHandler/SignUpContextHandler"
import { ROUTES } from "../../../../../../navigation/routes"
import {
  StyledFormContainer,
  StyledFormTitle,
  inputCss,
  invalidInputCss,
  Input,
  StyledContainer,
} from "../../../PaymentStep/components/PaymentForm/PaymentForm"
import { firebaseApiHandler } from "../../../../../../api/payment/payment"

const InputWrapper = styled.div`
  margin-top: 15.5px;

  select.regionCountryDropdown {
    ${inputCss};
    color: ${theme.colors.white};
    background: none;
    line-height: 1.2em;
    display: block;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: inherit;
    appearance: none;
    background-image: url(/svgs/arrowBottom.svg);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 15px;

    &:focus {
      outline: none;
      border-color: ${theme.colors.secondary};
    }

    &.defaultOptionSelected {
      color: ${theme.colors.lightGrey};
      opacity: 1;
    }

    &.selectError {
      ${invalidInputCss}
    }

    &:after {
      display: none;
    }
  }
`

const Form: React.FC<{
  coachSlug: string
  handleError: (error: any) => void
}> = ({ coachSlug, handleError }) => {
  const stripe = useStripe()
  const validationSchema = useBillingFormSchema()
  const { billingAddress, setBillingAddress } = useSignUpContext()

  const onSubmit = async (
    {
      addressNumber,
      addressName,
      city,
      stateProvince,
      country,
      zipPostCode,
    }: {
      addressNumber: string
      addressName: string
      city: string
      stateProvince: string
      country: string
      zipPostCode: string
    },
    {
      setSubmitting,
    }: {
      setSubmitting: (arg0: boolean) => void
    }
  ): Promise<any> => {
    const inputToBillingAddress = {
      line1: addressName,
      line2: addressNumber,
      city,
      country,
      state: stateProvince,
      postal_code: zipPostCode,
    }
    return firebaseApiHandler
      .addBillingAddress(inputToBillingAddress)
      .then(() => {
        setBillingAddress(inputToBillingAddress)
        setSubmitting(false)
        navigate(
          ROUTES.coachSignupPayment.paramPath(
            coachSlug,
            ROUTES.coach.paramPath(coachSlug)
          )
        )
      })
      .catch(error => {
        Sentry.captureMessage(
          "Something went wrong when adding the billing address"
        )
        Sentry.captureException(error)
        setSubmitting(false)
        handleError(error)
      })
  }

  return (
    <StyledContainer>
      <StyledFormTitle>Billing Address</StyledFormTitle>
      <Formik
        initialValues={{
          addressNumber: billingAddress.line2,
          addressName: billingAddress.line1,
          city: billingAddress.city,
          stateProvince: billingAddress.state,
          country: billingAddress.country,
          zipPostCode: billingAddress.postal_code,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => {
          const submitDisabled = !isValid || isSubmitting
          return (
            <form onSubmit={handleSubmit}>
              <StyledFormContainer>
                <InputWrapper>
                  <Input
                    name="addressNumber"
                    invalid={touched.addressNumber && !!errors.addressNumber}
                    value={values.addressNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Apartment, Suite Number"
                    type="text"
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    name="addressName"
                    invalid={touched.addressName && !!errors.addressName}
                    value={values.addressName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Street Number and Name"
                    type="text"
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    name="city"
                    invalid={touched.city && !!errors.city}
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="City"
                    type="text"
                  />
                </InputWrapper>
                <InputWrapper>
                  <CountryDropdown
                    name="country"
                    value={values.country}
                    valueType="short"
                    invalid={touched.country && !values.country}
                    onChange={(_, e) => handleChange(e)}
                    onBlur={handleBlur}
                    classes={`regionCountryDropdown ${
                      !values.country ? "defaultOptionSelected" : ""
                    } ${
                      !values.country && touched.country ? "selectError" : ""
                    }`}
                    defaultOptionLabel="Country"
                  />
                </InputWrapper>
                <InputWrapper>
                  <RegionDropdown
                    name="stateProvince"
                    value={values.stateProvince}
                    valueType="short"
                    country={values.country}
                    countryValueType="short"
                    invalid={touched.stateProvince && !!errors.stateProvince}
                    onChange={(_, e) => handleChange(e)}
                    onBlur={handleBlur}
                    blankOptionLabel="State/Province"
                    defaultOptionLabel="State/Province"
                    classes={`regionCountryDropdown ${
                      !values.stateProvince ? "defaultOptionSelected" : ""
                    } ${
                      !values.stateProvince && touched.stateProvince
                        ? "selectError"
                        : ""
                    }`}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Input
                    name="zipPostCode"
                    invalid={touched.zipPostCode && !!errors.zipPostCode}
                    value={values.zipPostCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Zip/Postal Code"
                    type="text"
                  />
                </InputWrapper>
              </StyledFormContainer>
              <div>
                <ActionButton
                  theme={ActionButtonTheme.SECONDARY}
                  type="submit"
                  disabled={submitDisabled}
                  loading={isSubmitting}
                >
                  Next
                  {isSubmitting && <ButtonLoadingIndicator />}
                </ActionButton>
              </div>
            </form>
          )
        }}
      </Formik>
    </StyledContainer>
  )
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY || "")

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Public+Sans:400",
    },
  ],
}

const BillingForm: React.FC<{
  handleError: (error: any) => void
  coachSlug: string
}> = ({ coachSlug, handleError }) => (
  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
    <Form coachSlug={coachSlug} handleError={handleError} />
  </Elements>
)

export default BillingForm
