import React from "react"
import styled from "styled-components"
import { getResponsiveMobileHeight, retinaMediaQuery } from "../../helpers"
import theme from "../../theme"

const StyledBanner = styled.div<{
  image: string
  image2x: string
}>`
  background: ${theme.colors.secondary};
  height: ${getResponsiveMobileHeight(383)};
  //background-position: bottom center;
  //background-size: contain;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:  top center;
  background-image: url("${props => props.image}");
  
  ${retinaMediaQuery} {
    background-image: url("${props => props.image2x}");
  }
  
`

interface Props {
  image: string
  image2x: string
}

const BannerImage: React.FC<Props> = ({ image, image2x, ...props }) => (
  <StyledBanner image={image} image2x={image2x} {...props} />
)

export default BannerImage
