/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Formik } from "formik"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { StyledContainer, StyledStepHeader } from "../InfoStep/InfoStep"
import ActionButton, {
  ButtonLoadingIndicator,
  StyledSpinner,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import ValidatedInput from "../../../../ui/inputs/ValidatedInput/ValidatedInput"
import TermsAndConditionsCheck from "../CreateStep/views/EmailSignUpView/components/TermsAndConditionsCheck/TermsAndConditionsCheck"
import UserProfilePictureHandler from "../../../AccountScreen/views/AccountSettingsView/components/UserProfilePictureHandler/UserProfilePictureHandler"
import { StyledHeading } from "../PaymentStep/PaymentStep"
import {
  useHasUserAcceptedTerms,
  useUserInfo,
} from "../../../../auth/components/AuthHandler/hooks"
import {
  getFirstName,
  setUserTermsAccepted,
  setUserUsernameAndTerms,
} from "../../../../firebase/user"
import { useSignUpBasePath } from "../../components/SignUpContextHandler/SignUpContextHandler"
import {
  useFirebaseDbUser,
  useIsUserAuthenticated,
  useUserUid,
} from "../../../../auth/components/AuthHandler/AuthHandler"
import { ROUTES } from "../../../../navigation/routes"
import { firebaseApiHandler } from "../../../../api/payment/payment"
import { useSignUpDetailsFormSchema } from "./validation"
import { handleFirebaseErrors } from "../CreateStep/views/EmailSignUpView/EmailSignUpView"
import ProfileImageHelper from "./components/ProfileImageHelper/ProfileImageHelper"
import { mixpanelSetProfileDetails } from "../../../../analytics/mixpanel"
import { StyledFirebaseError } from "../CreateStep/views/SelectSignUpOptionView/components/FirebaseErrorMessage/FirebaseErrorMessage"
import { useSiteContext } from "../../../../components/SiteWrapper/components/SiteContextWrapper/SiteContextWrapper"

const StyledFormSection = styled.div`
  margin-top: 24px;
`

const StyledImageSection = styled.div`
  margin-top: 40px;
`

const StyledButtonWrapper = styled.div`
  margin-top: 40px;
`

const isValidUsername = (username: string): boolean => {
  return !!username
}

interface Props extends RouteComponentProps {
  signUpDestination: string
  termsRequired?: boolean
  noCoach: boolean
}

const DetailsStep: React.FC<Props> = ({
  signUpDestination,
  termsRequired = true,
}) => {
  const mixpanel = useMixpanel()
  const uid = useUserUid()
  const authenticated = useIsUserAuthenticated()
  const basePath = useSignUpBasePath()

  const { name, username, image, email } = useUserInfo()

  const [usernameRequired] = useState(!username || !isValidUsername(username))
  const validationSchema = useSignUpDetailsFormSchema(
    usernameRequired,
    termsRequired
  )
  const termsAccepted = useHasUserAcceptedTerms()

  const handleNextStep = () => {
    navigate(`${basePath}${signUpDestination}`)
  }

  const onSubmit = async (
    {
      username: usernameInput,
    }: {
      username: string
    },
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: {
      setErrors: (arg0: any) => void
      setStatus: (arg0: any) => void
      setSubmitting: (arg0: boolean) => void
    }
  ): Promise<any> => {
    if (!usernameRequired) {
      mixpanelSetProfileDetails(mixpanel, uid, {
        email,
        username: usernameInput,
        avatar: image,
      })
      return setUserTermsAccepted()
        .then(() => {
          handleNextStep()
        })
        .catch(error => {
          console.error(error)
          Sentry.captureMessage(
            "Something went wrong when setting user terms accepted"
          )
          Sentry.captureException(error)
          handleFirebaseErrors(error, setErrors, setStatus)
          setSubmitting(false)
        })
    }
    return firebaseApiHandler
      .checkForDuplicateUsername(usernameInput)
      .then(() => {
        setUserUsernameAndTerms(usernameInput)
          .then(() => {
            mixpanelSetProfileDetails(mixpanel, uid, {
              email,
              username: usernameInput,
              avatar: image,
            })
            handleNextStep()
          })
          .catch(error => {
            console.error(error)
            Sentry.captureMessage(
              "Something went wrong when setting username and terms"
            )
            Sentry.captureException(error)
            handleFirebaseErrors(error, setErrors, setStatus)
            setSubmitting(false)
          })
      })
      .catch(() => {
        setErrors({
          username: "Username already being used.",
        })
        setSubmitting(false)
      })
  }

  useEffect(() => {
    if (username && termsAccepted && image) {
      handleNextStep()
    }
  }, [username, termsAccepted, image])

  useEffect(() => {
    if (!authenticated) {
      navigate(`${basePath}${ROUTES.signUp.subPath}`)
    }
  }, [authenticated])

  const isInitialValid =
    !!username && (termsRequired ? termsAccepted : true) && !image

  return (
    <StyledContainer>
      <StyledStepHeader>{/* <div>Step Two</div> */}</StyledStepHeader>
      <Formik
        initialValues={{
          username,
          profileImage: !!image,
          terms: termsAccepted,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        isInitialValid={isInitialValid}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
        }) => {
          const submitDisabled = !isValid || isSubmitting

          return (
            <div>
              <StyledHeading>Welcome {getFirstName(name)}</StyledHeading>
              <StyledFormSection>
                {usernameRequired && (
                  <div>
                    <ValidatedInput
                      name="username"
                      label="Username"
                      type="text"
                      valid={touched.username && !errors.username}
                      errorMessage={errors.username || ""}
                      invalid={touched.username && !!errors.username}
                      onBlur={handleBlur}
                      value={values.username}
                      required
                      onChange={e => {
                        let value = e.target.value || ""
                        value = value.toLowerCase().trim()
                        setFieldValue("username", value)
                      }}
                    />
                  </div>
                )}
                <StyledImageSection>
                  <UserProfilePictureHandler
                    required
                    onImageUpload={() => {
                      setFieldValue("profileImage", true)
                    }}
                  />
                  <ProfileImageHelper
                    setHasImage={() => {
                      setFieldValue("profileImage", true)
                    }}
                  />
                </StyledImageSection>
                {termsRequired && (
                  <div>
                    <TermsAndConditionsCheck
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.terms}
                    />
                  </div>
                )}
              </StyledFormSection>
              <StyledButtonWrapper>
                <ActionButton
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
                  // @ts-ignore
                  onClick={handleSubmit}
                  type="button"
                  disabled={submitDisabled}
                  loading={isSubmitting}
                >
                  Next
                  {isSubmitting && <ButtonLoadingIndicator />}
                </ActionButton>
              </StyledButtonWrapper>
              {status && status.firebaseError && (
                <StyledFirebaseError>
                  {status.firebaseError}
                </StyledFirebaseError>
              )}
            </div>
          )
        }}
      </Formik>
    </StyledContainer>
  )
}

export const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const DetailsStepWrapper: React.FC<Props> = props => {
  const [waitForDBUser, setWaitForDBUser] = useState(true)
  const dbUser = useFirebaseDbUser()
  const userLoaded = !!dbUser
  const { setNavDisabled } = useSiteContext()

  useEffect(() => {
    setNavDisabled(true)
    return () => {
      setNavDisabled(false)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setWaitForDBUser(false) // wait 3 seconds for db user to load before giving up
    }, 3000)
  }, [])

  if (!userLoaded && waitForDBUser) {
    return (
      <StyledContainer>
        <StyledSpinnerContainer>
          <StyledSpinner />
        </StyledSpinnerContainer>
      </StyledContainer>
    )
  }
  return <DetailsStep {...props} />
}

export default DetailsStep
