import React from "react"
import styled, { css } from "styled-components"
import ReactPlayer from "react-player"
import RawModal from "../RawModal/RawModal"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../responsive"
import CloseButton from "../../buttons/CloseButton/CloseButton"

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${MOBILE_BREAKPOINT} {
    padding: 15px;
  }

  video {
    width: auto;
    display: block;
    position: relative;
    z-index: 1000;
    max-height: 85vh;
  }
`

const videoClass = css`
  //position: absolute;
  //top: 0;
  //left: 0;
  position: relative;
`

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const StyledVideoContainer = styled.div`
  position: relative;
`

const StyledCloseButtonWrapper = styled.div`
  position: absolute;
  top: -36px;
  right: 0;

  ${DESKTOP_BREAKPOINT} {
    right: -36px;
  }
`

const VideoOverlay: React.FC<{
  close: () => void
  videoUrl: string
}> = ({ close, videoUrl }) => {
  return (
    <RawModal onClose={close} darker>
      <StyledVideoWrapper>
        <StyledBackdrop onClick={close} />
        <StyledVideoContainer>
          <StyledCloseButtonWrapper>
            <CloseButton onClick={close} />
          </StyledCloseButtonWrapper>
          <ReactPlayer
            className={videoClass}
            url={videoUrl}
            playing
            controls
            width="auto"
            height="100%"
          />
        </StyledVideoContainer>
      </StyledVideoWrapper>
    </RawModal>
  )
}

export default VideoOverlay
