import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import CurrencyFormat from "react-currency-format"
import { StyledContainer, StyledStepHeader } from "../InfoStep/InfoStep"
import { ROUTES } from "../../../../navigation/routes"
import { useIsUserAuthenticated } from "../../../../auth/components/AuthHandler/AuthHandler"
import {
  useSignUpBasePath,
  useSignUpContext,
} from "../../components/SignUpContextHandler/SignUpContextHandler"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import { getCoachName, ICMSCoach } from "../../../../data/cms/coach"
import PaymentForm from "./components/PaymentForm/PaymentForm"
import PaymentErrorView from "./components/PaymentErrorView/PaymentErrorView"
import {
  getSignUpFlowPaymentErrorHelpUrl,
  ICMSSignUpFlow,
} from "../../../../data/cms/signUpFlow"
import {
  getFirestoreOfferingStripePriceAmount,
  getFirestoreOfferingStripeCountryTax,
} from "../../../../firebase/offerings"
import PriceTaxBreakdown from "./components/PriceTaxBreakDown/PriceTaxBreakdown"
import theme from "../../../../ui/theme/index"
import { BackIcon } from "../../../AccountScreen/views/AccountSettingsView/AccountSettingsView"

export const StyledHeading = styled(MediumPlusHeading)`
  line-height: 30px;
`

const StyledDescription = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-top: 8px;
`

export const StyledBackButton = styled.span`
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.semiBold};

  & svg {
    path {
      stroke: ${theme.colors.primary};
    }
  }
`

interface Props extends RouteComponentProps {
  coach: ICMSCoach
  content: ICMSSignUpFlow
}

const PaymentStep: React.FC<Props> = ({ coach, content }) => {
  const authenticated = useIsUserAuthenticated()
  const basePath = useSignUpBasePath()
  const { coachOffering, billingAddress } = useSignUpContext()
  const [paymentError, setPaymentError] = useState<any>()

  const subscriptionPrice = getFirestoreOfferingStripePriceAmount(coachOffering)
  const offeringTaxes = getFirestoreOfferingStripeCountryTax(
    coachOffering,
    billingAddress.country
  )
  useEffect(() => {
    if (!authenticated) {
      navigate(`${basePath}${ROUTES.signUp.subPath}`, {
        replace: true,
      })
    }
  }, [authenticated])
  if (paymentError)
    return (
      <PaymentErrorView
        clearError={() => {
          setPaymentError(undefined)
        }}
        helpUrl={getSignUpFlowPaymentErrorHelpUrl(content)}
      />
    )
  return (
    <StyledContainer>
      <StyledBackButton
        as={Link}
        to={
          authenticated
            ? ROUTES.coachSignupBilling.paramPath(
                coach.slug,
                ROUTES.coach.paramPath(coach.slug)
              )
            : ROUTES.coachSignup.paramPath(
                coach.slug,
                ROUTES.coach.paramPath(coach.slug)
              )
        }
      >
        <BackIcon />
      </StyledBackButton>
      <StyledStepHeader>{/* <div>Step Three</div> */}</StyledStepHeader>
      <div>
        <StyledHeading>Subscribe to {getCoachName(coach)}</StyledHeading>
      </div>
      {offeringTaxes ? (
        <>
          <PriceTaxBreakdown
            price={subscriptionPrice}
            applicableTaxes={offeringTaxes}
          />
        </>
      ) : (
        <>
          <StyledDescription>
            <CurrencyFormat
              value={subscriptionPrice / 100}
              prefix="$"
              suffix=" USD / month"
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
            />
          </StyledDescription>
        </>
      )}
      <PaymentForm
        coachName={getCoachName(coach)}
        coachId={coach.coachId}
        coachPrice={getFirestoreOfferingStripePriceAmount(coachOffering)}
        handleError={setPaymentError}
      />
    </StyledContainer>
  )
}

export default PaymentStep
