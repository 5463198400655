import { firebaseApp, firebaseStorage } from "./client"

const storageRef = firebaseStorage.ref()

const imagesRef = storageRef.child("images")

export const getImageFileExtension = (image: string): string => {
  const fileExtenstion = image.substring(
    "data:image/".length,
    image.indexOf(";base64")
  )

  if (fileExtenstion === "png") {
    return "png"
  }

  return "jpg"
}

export const getUserImagesRef = (uid: string) => {
  return imagesRef.child(uid)
}

export const uploadUserImage = (uid: string, image: string) => {
  const imageExtension = getImageFileExtension(image)
  const uploadTask = getUserImagesRef(`${uid}.${imageExtension}`).putString(
    image,
    "data_url"
  )
  return uploadTask
    .then(snapshot => {
      return snapshot.ref.getDownloadURL()
    })
    .then(downloadURL => {
      return downloadURL
    })
}

export const updateUserProfileImage = (image: string) => {
  const user = firebaseApp.auth().currentUser
  if (!user) {
    return Promise.reject()
  }
  return uploadUserImage(user?.uid, image).then(downloadURL => {
    return user
      ?.updateProfile({
        photoURL: downloadURL,
      })
      .then(() => {
        return downloadURL
      })
  })
}
