import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { Link } from "gatsby"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import LeftArrowIcon from "../../../../assets/inline-assets/arrowLeft.svg"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { minHeightCss } from "../../../SignUpScreen/views/InfoStep/InfoStep"
import { StyledOption, StyledOptions } from "../AccountView/AccountView"
import theme from "../../../../ui/theme"
import { ROUTES } from "../../../../navigation/routes"
import { getFirstName, getLastName } from "../../../../firebase/user"
import { useUserInfo } from "../../../../auth/components/AuthHandler/hooks"
import UserProfilePictureHandler from "./components/UserProfilePictureHandler/UserProfilePictureHandler"
import PageLocation from "../../../../analytics/components/PageLocation/PageLocation"
import RightArrow from "../../../../assets/inline-assets/arrowRight.svg"
import {
  useFirebaseUser,
  useIsFirebaseUserPasswordSignUp,
} from "../../../../auth/components/AuthHandler/AuthHandler"

export const BackIcon = () => <LeftArrowIcon width="20" height="20" />

export const StyledContainer = styled.div`
  ${minHeightCss};
  padding-top: 30px;
  padding-bottom: 30px;
`

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 38px;
`

export const StyledBackButton = styled.button`
  margin-right: 5px;
  padding: 5px;
  margin-left: -5px;
`

const StyledProfilePicSection = styled.div`
  color: ${theme.colors.darkGrey};
  margin-bottom: 23px;
`

const StyledOptionsWrapper = styled(StyledOptions)`
  > li:last-child {
    border-bottom: 1px solid #cfcfcf;
  }
`

const StyledOptionLabel = styled.div`
  min-width: 100px;
  margin-right: 10px;
`

const StyledOptionValue = styled.div`
  font-weight: ${theme.fontWeights.bold};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledRightArrow = styled.div``

const AccountSettingsView: React.FC<RouteComponentProps> = () => {
  const { username, email, name } = useUserInfo()
  const isPasswordSignUp = useIsFirebaseUserPasswordSignUp()
  return (
    <PageLocation pageName={ROUTES.accountSettings.name}>
      <StyledContainer>
        <MaxWidthContent>
          <StyledHeader>
            <StyledBackButton as={Link} to={ROUTES.account.path}>
              <BackIcon />
            </StyledBackButton>
            <MediumPlusHeading>User Settings</MediumPlusHeading>
          </StyledHeader>
          <StyledProfilePicSection>
            <UserProfilePictureHandler isAccountView />
          </StyledProfilePicSection>
          <StyledOptionsWrapper>
            <li>
              <StyledOption as={Link} to={ROUTES.accountSettingsUsername.path}>
                <StyledOptionLabel>Username</StyledOptionLabel>
                <StyledOptionValue>@{username}</StyledOptionValue>
                <StyledRightArrow>
                  <RightArrow width={16} height={16} />
                </StyledRightArrow>
              </StyledOption>
            </li>
            <li>
              <StyledOption>
                <StyledOptionLabel>Email</StyledOptionLabel>
                <StyledOptionValue>{email}</StyledOptionValue>
              </StyledOption>
            </li>
            {isPasswordSignUp && (
              <li>
                <StyledOption
                  as={Link}
                  to={ROUTES.accountSettingsPassword.path}
                >
                  <StyledOptionLabel>Password</StyledOptionLabel>
                  <StyledOptionValue />
                  <StyledRightArrow>
                    <RightArrow width={16} height={16} />
                  </StyledRightArrow>
                </StyledOption>
              </li>
            )}
          </StyledOptionsWrapper>
        </MaxWidthContent>
      </StyledContainer>
    </PageLocation>
  )
}

export default AccountSettingsView
