import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { ROUTES } from "../../../../navigation/routes"

const NoCoachCompletedStep: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    navigate(ROUTES.discover.path, {
      replace: true,
    })
  }, [])
  return null
}

export default NoCoachCompletedStep
