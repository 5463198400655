import React from "react"
import styled from "styled-components"
import MaxWidthContent from "../MaxWidthContent/MaxWidthContent"
import theme from "../../theme"
import MediumHeading from "../../typography/MediumHeading/MediumHeading"

const StyledContainer = styled(MaxWidthContent)`
  margin-top: ${theme.spacing.medium}px;
`

const StyledDescription = styled.div`
  margin-top: 8px;
`

interface Props {
  name: string
  bio: string
}

const ProfileDescriptionBlock: React.FC<Props> = ({ name, bio }) => (
  <StyledContainer>
    <MediumHeading as="h1">{name}</MediumHeading>
    <StyledDescription>
      <p>{bio}</p>
    </StyledDescription>
  </StyledContainer>
)

export default ProfileDescriptionBlock
