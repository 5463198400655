import styled from "styled-components"
import theme from "../../theme"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: ${theme.grid.mobileGutterWidth}px;
`

export default Grid
