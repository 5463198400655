import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import ImageUploading, { ImageListType } from "react-images-uploading/dist"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { bgImageCss } from "../../../../../../ui/shared"
import { updateUserProfileImage } from "../../../../../../firebase/storage"
import { useUserInfo } from "../../../../../../auth/components/AuthHandler/hooks"
import Tick from "../../../../../../assets/inline-assets/tick.svg"
import {
  useAuthHandlerContext,
  useUserUid,
} from "../../../../../../auth/components/AuthHandler/AuthHandler"
import {
  mixpanelEventEditProfile,
  mixpanelSetProfileDetails,
} from "../../../../../../analytics/mixpanel"
import theme from "../../../../../../ui/theme"
import AddIcon from "../../../../../../assets/inline-assets/add.svg"

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledThumb = styled.div<{
  image: string
  reducedMargin: boolean
}>`
  ${bgImageCss};
  background-color: ${theme.colors.lightGrey};
  background-image: url(${props => props.image});
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-right: ${props => (props.reducedMargin ? 12 : 40)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    path {
      stroke: ${theme.colors.white};
    }
  }
`

const StyledButtonWrapper = styled.div`
  flex: 1;
`

const StyledChangeProfilePicButton = styled.button`
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: inherit;
`

const StyledRequiredMessage = styled.div`
  font-size: 13px;
  padding-right: 16px;

  svg {
    width: 19px;
    height: 19px;
  }
`

const StyledErrorContainer = styled.div`
  color: ${theme.colors.primary};
`

const UserProfilePictureHandler: React.FC<{
  isAccountView?: boolean
  required?: boolean
  onImageUpload?: () => void
}> = ({ isAccountView = false, required = false, onImageUpload }) => {
  const mixpanel = useMixpanel()
  const uid = useUserUid()
  const { refetchFbUser } = useAuthHandlerContext()
  const { image } = useUserInfo()
  const onImageChange = (imageList: ImageListType) => {
    const imageToUpload = get(imageList, "[0].dataURL", "")
    if (imageToUpload) {
      if (onImageUpload) {
        onImageUpload()
      }
      updateUserProfileImage(imageToUpload).then(imageURL => {
        refetchFbUser()
        mixpanelSetProfileDetails(mixpanel, uid, { avatar: imageURL })
        if (isAccountView) {
          mixpanelEventEditProfile(mixpanel)
        }
      })
    }
  }
  return (
    <ImageUploading
      acceptType={["jpg", "jpeg", "png", "JPG", "PNG", "JPEG"]}
      onChange={onImageChange}
    >
      {({ onImageUpload: handleImageUpload, imageList, errors }) => {
        const profileImage = get(imageList, "[0].dataURL", image)
        return (
          <>
            <StyledContainer>
              <StyledThumb
                image={profileImage}
                onClick={handleImageUpload}
                reducedMargin={required}
              >
                {!profileImage && <AddIcon />}
              </StyledThumb>
              <StyledButtonWrapper>
                <StyledChangeProfilePicButton onClick={handleImageUpload}>
                  {profileImage ? "Change Profile Pic" : "Set Profile Pic"}
                </StyledChangeProfilePicButton>
              </StyledButtonWrapper>
              {required && (
                <StyledRequiredMessage>
                  {image ? <Tick /> : "Required"}
                </StyledRequiredMessage>
              )}
            </StyledContainer>
            <StyledErrorContainer>
              {errors.maxNumber && (
                <span>Number of selected images exceed maxNumber</span>
              )}
              {errors.acceptType && (
                <span>
                  Your selected file type is not allowed. Please upload a .jpg
                  or .png image.
                </span>
              )}
              {errors.maxFileSize && (
                <span>Selected file size exceed maxFileSize</span>
              )}
              {errors.resolution && (
                <span>Selected file is not match your desired resolution</span>
              )}
            </StyledErrorContainer>
          </>
        )
      }}
    </ImageUploading>
  )
}

export default UserProfilePictureHandler
