import { get } from "lodash"
import TimeFormat from "hh-mm-ss"
import firebase from "firebase/app"
import { timeAgo } from "../utils/time"
import { getRandomInt } from "../utils/numbers"
import { firebaseDatabase } from "./client"

export interface IGatsbyVideo {
  videoId: string
  playbackId: string
  coachId: string
  title: string
  duration: number
  muxCreatedAt: number
  thumbnailOffset: number
  topic: string
}

export const getVideoTopic = (video: IGatsbyVideo): string => {
  return get(video, "topic", "") || ""
}

export const getTopicVideos = (
  topic: string,
  videos: IGatsbyVideo[]
): IGatsbyVideo[] => {
  return videos.filter(video => {
    return getVideoTopic(video).toLowerCase() === topic.toLowerCase()
  })
}

export const getVideoVodLink = (video: IGatsbyVideo): string => {
  const coachId = get(video, "coachId", "") || ""
  const videoId = get(video, "videoId", "") || ""
  return `/vods/${coachId}/${videoId}`
}

export const sortVideosByCreated = (videos: IGatsbyVideo[]): IGatsbyVideo[] => {
  return videos.sort((videoA, videoB) => {
    return videoB.muxCreatedAt - videoA.muxCreatedAt
  })
}

export const filterVideosByDate = (
  videos: IGatsbyVideo[],
  numberOfDays: number | null
): IGatsbyVideo[] => {
  if (!numberOfDays) {
    return videos
  }
  const date = new Date()
  date.setDate(date.getDate() - numberOfDays)

  const filteredVideos = videos.filter(video => {
    return new Date(video.muxCreatedAt) > date
  })

  return filteredVideos
}

export const getVideoCreatedTimestamp = (video: IGatsbyVideo): number => {
  return get(video, "muxCreatedAt", 0) || 0
}

export const getVideoFormattedTimeAgo = (video: IGatsbyVideo): string => {
  const createdTimestamp = getVideoCreatedTimestamp(video)
  return timeAgo.format(createdTimestamp)
}

export const getVideoThumbnailRaw = (
  playbackId: string,
  thumbnailOffset: number
): string => {
  return `https://image.mux.com/${playbackId}/thumbnail.jpg?time=${thumbnailOffset}`
}

export const getVideoThumbnail = (video: IGatsbyVideo): string => {
  const pbID = get(video, "playbackId", "")
  const thumbnailOffset = get(video, "thumbnailOffset", 0)
  return getVideoThumbnailRaw(pbID, thumbnailOffset)
}

export const getVideoTitle = (video: IGatsbyVideo): string => {
  return get(video, "title", "") || ""
}

export const getVideoDuration = (video: IGatsbyVideo): number => {
  return get(video, "duration", 0) || 0
}

const stripLeadingZero = (string: string): string => {
  if (string && string.length > 2 && string[0] === "0") {
    return string.substring(1, string.length)
  }
  return string
}

export const getFormattedVideoDuration = (video: IGatsbyVideo): string => {
  const duration = Math.round(getVideoDuration(video))
  // over or equal to 60 minutes
  if (duration >= 60 * 60) {
    const formatted = TimeFormat.fromS(duration, "hh:mm:ss")
    return stripLeadingZero(formatted)
  }
  if (duration > 60) {
    const formatted = TimeFormat.fromS(duration, "mm:ss")
    return stripLeadingZero(formatted)
  }
  const formatted = TimeFormat.fromS(duration, "mm:ss")
  return stripLeadingZero(formatted)
}

export const firestoreVideosCollection = firebaseDatabase.collection("videos")

export const getFirestoreVideoDoc = (videoId: string) => {
  return firestoreVideosCollection.doc(videoId)
}

export const incrementVideoViewCount = (videoId: string) => {
  const randomShard = getRandomInt(0, 9)
  const viewsShardDoc = getFirestoreVideoDoc(videoId)
    .collection("views")
    .doc(randomShard.toString())
  return viewsShardDoc.update({
    count: firebase.firestore.FieldValue.increment(1),
  })
}
