import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { SmallerActionButton } from "../../buttons/ActionButton/ActionButton"
import theme from "../../theme"
import { ROUTES } from "../../../navigation/routes"

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.darkGrey};
  margin-bottom: -15px;
`

const StyledButton = styled(SmallerActionButton)`
  max-width: 216px;
  transform: translateY(-50%);
`

const CompactFooter: React.FC = () => (
  <StyledContainer>
    <StyledButton as={Link} to={ROUTES.discover.path}>
      Explore Youspired
    </StyledButton>
  </StyledContainer>
)

export default CompactFooter
