import React from "react"
import styled from "styled-components"
import CurrencyFormat from "react-currency-format"
import {
  IFirestoreOfferingTax,
  getFirestoreOfferingStripeCountryTaxPercentage,
} from "../../../../../../firebase/offerings"

interface Props {
  price: number
  applicableTaxes: IFirestoreOfferingTax | null
}

const StyledItemDescription = styled.p`
  font-size: 18px;
  line-height: 28px;

  &:first-of-type {
    margin-top: 8px;
  }
`

const getTaxableAmount = (
  productPrice: number,
  applicableTax: IFirestoreOfferingTax | null
) => {
  if (applicableTax === null) {
    return 0
  }

  const taxPercenatge = getFirestoreOfferingStripeCountryTaxPercentage(
    applicableTax
  )
  return productPrice * (taxPercenatge / 100)
}

const PriceTaxBreakdown: React.FC<Props> = ({
  price = 0,
  applicableTaxes = null,
}) => {
  const productPrice = price / 100
  const totalTaxes = getTaxableAmount(productPrice, applicableTaxes)
  const totalPrice = productPrice + totalTaxes
  return (
    <>
      <StyledItemDescription>
        <CurrencyFormat
          value={productPrice}
          prefix="Subscription $"
          suffix=" USD"
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
        />
      </StyledItemDescription>
      <StyledItemDescription>
        <CurrencyFormat
          value={totalTaxes}
          prefix="GST $"
          suffix=" USD"
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
        />
      </StyledItemDescription>
      <StyledItemDescription>
        <CurrencyFormat
          value={totalPrice}
          prefix="Total $"
          suffix=" USD / month"
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
        />
      </StyledItemDescription>
    </>
  )
}

export default PriceTaxBreakdown
