import React from "react"
import styled from "styled-components"
import SessionPreview, {
  StyledContainer as StyledSessionPreviewContainer,
} from "./components/SessionPreview/SessionPreview"
import {
  getVideoVodLink,
  IGatsbyVideo,
  sortVideosByCreated,
  filterVideosByDate,
} from "../../../firebase/video"
import { DESKTOP_BREAKPOINT } from "../../responsive"
import { ResponsiveScrollableContainer } from "../../layout"
import { useModalsHandlerContext } from "../../../components/ModalsHandler/ModalsHandler"

const StyledContainer = styled(ResponsiveScrollableContainer)``

const StyledSessionPreview = styled.li`
  &:not(:last-child) {
    margin-right: 24px;

    ${DESKTOP_BREAKPOINT} {
      margin-right: 20.25px;
    }
  }
`

const Placeholder: React.FC = () => (
  <>
    <StyledSessionPreview>
      <StyledSessionPreviewContainer placeholder />
    </StyledSessionPreview>
    <StyledSessionPreview>
      <StyledSessionPreviewContainer placeholder />
    </StyledSessionPreview>
    <StyledSessionPreview>
      <StyledSessionPreviewContainer placeholder />
    </StyledSessionPreview>
    <StyledSessionPreview>
      <StyledSessionPreviewContainer placeholder />
    </StyledSessionPreview>
  </>
)

const SessionsList: React.FC<{
  videos: IGatsbyVideo[]
  loading?: boolean
  subscriptionRequired?: boolean
  isAuthenticated?: boolean
  isSubscribed?: boolean
  coachName?: string
  coachSlug?: string
  coachPrice?: string
  showPreviousDays?: number | null
}> = ({
  videos,
  loading = false,
  subscriptionRequired = false,
  isSubscribed,
  isAuthenticated,
  coachName = "",
  coachSlug = "",
  coachPrice = "",
  showPreviousDays = null,
}) => {
  const { setSignUpPrompt } = useModalsHandlerContext()
  const filteredVideos = filterVideosByDate(videos, showPreviousDays)
  return (
    <StyledContainer>
      {loading ? (
        <Placeholder />
      ) : (
        sortVideosByCreated(filteredVideos).map(video => {
          const onOpen = subscriptionRequired
            ? (event: any) => {
                if (!isSubscribed || !isAuthenticated) {
                  event.preventDefault()
                  setSignUpPrompt({
                    destination: getVideoVodLink(video),
                    coachId: video.coachId,
                    coachSlug,
                    coachName,
                    coachPrice,
                  })
                }
              }
            : undefined
          return (
            <StyledSessionPreview key={video.videoId}>
              <SessionPreview video={video} onOpen={onOpen} />
            </StyledSessionPreview>
          )
        })
      )}
    </StyledContainer>
  )
}

export default SessionsList
