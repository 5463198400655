import React from "react"
import styled from "styled-components"
import ArrowLeftIcon from "../../../assets/inline-assets/arrowLeft.svg"

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
`

const BackButton: React.FC<any> = props => (
  <StyledButton {...props}>
    <ArrowLeftIcon />
  </StyledButton>
)

export default BackButton
