import React, { useState } from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { Link } from "gatsby"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import MaxWidthContent, {
  ResponsiveMaxWidthContent,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { bgImageCss } from "../../../../ui/shared"
import MediumHeading from "../../../../ui/typography/MediumHeading/MediumHeading"
import theme from "../../../../ui/theme"
import RightArrow from "../../../../assets/inline-assets/arrowRight.svg"
import ThumbnailIcon from "../../../../assets/inline-assets/thumbnail.svg"
import PaymentIcon from "../../../../assets/inline-assets/payment.svg"
import CoachesIcon from "../../../../assets/inline-assets/coaches.svg"
import HelpIcon from "../../../../assets/inline-assets/help.svg"
import CloseIcon from "../../../../assets/inline-assets/close.svg"
import ActionButton, {
  ActionButtonTheme,
  StyledSpinner,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import { minHeightCss } from "../../../SignUpScreen/views/InfoStep/InfoStep"
import { ROUTES } from "../../../../navigation/routes"
import {
  useIsStripeCustomer,
  useUserInfo,
} from "../../../../auth/components/AuthHandler/hooks"
import {
  DESKTOP_BREAKPOINT,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../../../ui/responsive"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../../../ui/typography/fonts"
import PageLocation from "../../../../analytics/components/PageLocation/PageLocation"
import { useHelpUrl } from "../../../../components/ForgotDetailsModal/ForgotDetailsModal"
import { firebaseApiHandler } from "../../../../api/payment/payment"

const StyledContainer = styled.div`
  ${minHeightCss};
  padding-top: 30px;
`

const StyledUserBadge = styled.div`
  margin-top: 40px;
  margin-bottom: 26px;
  display: flex;
  align-items: center;

  ${DESKTOP_BREAKPOINT} {
    margin-top: -32px;
  }
`

const StyledThumb = styled.div<{
  image: string
}>`
  ${bgImageCss};
  background-image: url(${props => props.image});
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
`

const StyledUserInfo = styled.div`
  flex: 1;
`

const StyledLabel = styled.div`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 18px;
  margin-top: 2px;
  font-weight: ${theme.fontWeights.medium};
`

export const StyledOptions = styled.ul`
  > li {
    border-top: 1px solid #cfcfcf;
  }
`

export const StyledOption = styled.div`
  padding: 17px 0;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
`

const StyledOptionIcon = styled.div`
  min-width: 30px;
  margin-right: 8px;
  display: flex;
  align-items: center;

  svg {
    display: block;
  }
`

const StyledOptionLabel = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 27px;
`

const StyledOptionArrow = styled.div`
  svg {
    width: auto;
    height: 20px;
  }
`

const StyledWantToCoachSection = styled(MaxWidthContent)`
  margin-top: 122px;
  margin-bottom: 66.5px;

  ${DESKTOP_BREAKPOINT} {
    padding-bottom: 50px;
  }

  > h3 {
    margin-bottom: 15.5px;
  }
`

const StyledThinHeading = styled.h2`
  ${fancyCondensedFontCss};
  font-size: 44px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 62.5px;
  text-transform: uppercase;

  span {
    ${fancyWideFontCss};
    color: ${theme.colors.primary};
  }
`

interface Props extends RouteComponentProps {
  applyToCoachUrl: string
}

const AccountView: React.FC<Props> = ({ applyToCoachUrl }) => {
  const { username, image } = useUserInfo()
  const isStripeCustomer = useIsStripeCustomer()
  const helpUrl = useHelpUrl()
  const [fetchingCustomerPortal, setFetchingCustomerPortal] = useState(false)
  const handleOpenCustomerPortal = () => {
    if (fetchingCustomerPortal) return
    setFetchingCustomerPortal(true)
    firebaseApiHandler
      .getStripeCustomerPortal()
      .then(response => response.data)
      .then(data => {
        const { customerPortalUrl } = data
        if (customerPortalUrl) {
          window.open(customerPortalUrl, "_blank")
        }
      })
      .finally(() => {
        setFetchingCustomerPortal(false)
      })
  }
  return (
    <PageLocation pageName={ROUTES.account.name}>
      <StyledContainer>
        <StyledDesktopOnly>
          <ResponsiveMaxWidthContent>
            <StyledThinHeading>
              Account<span>.</span>
            </StyledThinHeading>
          </ResponsiveMaxWidthContent>
        </StyledDesktopOnly>
        <MaxWidthContent>
          <StyledMobileOnly>
            <MediumPlusHeading>Account</MediumPlusHeading>
          </StyledMobileOnly>
          <StyledUserBadge as={Link} to={ROUTES.accountSettings.path}>
            <StyledThumb image={image} />
            <StyledUserInfo>
              <MediumHeading>@{username}</MediumHeading>
              <StyledLabel>User Settings</StyledLabel>
            </StyledUserInfo>
            <StyledOptionArrow>
              <RightArrow />
            </StyledOptionArrow>
          </StyledUserBadge>
          <StyledOptions>
            <li>
              <StyledOption as={Link} to={ROUTES.accountShortlist.path}>
                <StyledOptionIcon>
                  <ThumbnailIcon width="20" height="20" />
                </StyledOptionIcon>
                <StyledOptionLabel>Shortlisted Coaches</StyledOptionLabel>
                <StyledOptionArrow>
                  <RightArrow />
                </StyledOptionArrow>
              </StyledOption>
            </li>
            <li>
              <StyledOption as={Link} to={ROUTES.accountSubscriptions.path}>
                <StyledOptionIcon>
                  <CoachesIcon width="22" height="23" />
                </StyledOptionIcon>
                <StyledOptionLabel>My Coaches</StyledOptionLabel>
                <StyledOptionArrow>
                  <RightArrow />
                </StyledOptionArrow>
              </StyledOption>
            </li>
            {isStripeCustomer && (
              <li>
                <StyledOption as="button" onClick={handleOpenCustomerPortal}>
                  <StyledOptionIcon>
                    <PaymentIcon width="20" height="20" />
                  </StyledOptionIcon>
                  <StyledOptionLabel>
                    Manage Credit Card & Billing
                  </StyledOptionLabel>
                  {fetchingCustomerPortal ? (
                    <StyledSpinner dark />
                  ) : (
                    <StyledOptionArrow>
                      <RightArrow />
                    </StyledOptionArrow>
                  )}
                </StyledOption>
              </li>
            )}
            <li>
              <StyledOption
                as="a"
                href={helpUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledOptionIcon>
                  <HelpIcon width="20" height="20" />
                </StyledOptionIcon>
                <StyledOptionLabel>Help Center</StyledOptionLabel>
                <StyledOptionArrow>
                  <RightArrow />
                </StyledOptionArrow>
              </StyledOption>
            </li>
            <li>
              <StyledOption as={Link} to={ROUTES.signOut.path}>
                <StyledOptionIcon>
                  <CloseIcon width="18" height="18" />
                </StyledOptionIcon>
                <StyledOptionLabel>Sign out</StyledOptionLabel>
              </StyledOption>
            </li>
          </StyledOptions>
        </MaxWidthContent>
        <StyledWantToCoachSection>
          <MediumPlusHeading>Want to be a coach?</MediumPlusHeading>
          <div>
            <ActionButton
              theme={ActionButtonTheme.DARK_OUTLINE}
              as="a"
              href={applyToCoachUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply Here
            </ActionButton>
          </div>
        </StyledWantToCoachSection>
      </StyledContainer>
    </PageLocation>
  )
}

export default AccountView
