import { graphql } from "gatsby"
import { get } from "lodash"

export interface ICMSSignUpFlow {
  ctaIsVisible: boolean
  ctaButtonLabel: string
  ctaButtonDestination: string
  ctaIsAppleButton: boolean
  paymentErrorHelpUrl: string
}

export const getSignUpFlowCTAIsVisible = (content: ICMSSignUpFlow): boolean => {
  return get(content, "ctaIsVisible", false)
}

export const getSignUpFlowCTAIsAppleButton = (
  content: ICMSSignUpFlow
): boolean => {
  return get(content, "ctaIsAppleButton", false)
}

export const getSignUpFlowCTALabel = (content: ICMSSignUpFlow): string => {
  return get(content, "ctaButtonLabel", "") || ""
}

export const getSignUpFlowPaymentErrorHelpUrl = (
  content: ICMSSignUpFlow
): string => {
  return get(content, "paymentErrorHelpUrl", "") || ""
}

export const getSignUpFlowCTADestination = (
  content: ICMSSignUpFlow
): string => {
  return get(content, "ctaButtonDestination", "") || ""
}

export const query = graphql`
  fragment WebSignUpFlowDev on DatoCmsWebSignUpFlowDev {
    ctaIsVisible
    ctaButtonLabel
    ctaButtonDestination
    ctaIsAppleButton
    paymentErrorHelpUrl
  }

  fragment WebSignUpFlowStg on DatoCmsWebSignUpFlowStg {
    ctaIsVisible
    ctaButtonLabel
    ctaButtonDestination
    ctaIsAppleButton
    paymentErrorHelpUrl
  }

  fragment WebSignUpFlowUat on DatoCmsWebSignUpFlowUat {
    ctaIsVisible
    ctaButtonLabel
    ctaButtonDestination
    ctaIsAppleButton
    paymentErrorHelpUrl
  }

  fragment WebSignUpFlowPrd on DatoCmsWebSignUpFlowPrd {
    ctaIsVisible
    ctaButtonLabel
    ctaButtonDestination
    ctaIsAppleButton
    paymentErrorHelpUrl
  }
`
