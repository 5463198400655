import * as Yup from "yup"

export const useBillingFormSchema = () => {
  return Yup.object().shape({
    addressNumber: Yup.string(),
    addressName: Yup.string().required(),
    city: Yup.string().required(),
    stateProvince: Yup.string().required(),
    country: Yup.string().required(),
    zipPostCode: Yup.string().required(),
  })
}
