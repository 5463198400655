import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Thumbnail from "../../../Thumbnail/Thumbnail"
import RightArrowIcon from "../../../../../assets/inline-assets/arrowRight.svg"
import MediumHeading from "../../../../typography/MediumHeading/MediumHeading"
import theme from "../../../../theme"
import { useModalsHandlerContext } from "../../../../../components/ModalsHandler/ModalsHandler"
import { getVideoThumbnail, IGatsbyVideo } from "../../../../../firebase/video"
import { useIsUserAuthenticated } from "../../../../../auth/components/AuthHandler/AuthHandler"
import { formatStringAsUrlPath } from "../../../../../utils/string"
import { ROUTES } from "../../../../../navigation/routes"
import { loadingTextCss } from "../../../../../screens/CoachScreen/components/DesktopTopicsSection/DesktopTopicsSection"

const StyledContainer = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
  text-decoration: none;
`

const StyledInfo = styled.div`
  margin-left: 24px;
  flex: 1;
`

const StyledDetail = styled.p<{
  loading: boolean
}>`
  font-size: 16px;
  line-height: 27px;
  color: ${theme.colors.lightGrey};
  ${props => (props.loading ? loadingTextCss : "")};
`

const OpenButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: -5px;

  svg {
    width: 20px;
  }
`

const TopicPreview: React.FC<{
  coachId: string
  coachSlug: string
  coachName: string
  coachPrice: string
  topic: string
  topicVideos: IGatsbyVideo[]
  loading?: boolean
  isSubscribed: boolean
}> = ({
  coachId,
  coachSlug,
  coachName,
  coachPrice,
  topic,
  topicVideos,
  loading = false,
  isSubscribed,
}) => {
  const isAuthenticated = useIsUserAuthenticated()
  const { setSignUpPrompt } = useModalsHandlerContext()
  const thumbnail =
    topicVideos.length > 0 ? getVideoThumbnail(topicVideos[0]) : ""
  const destination = ROUTES.coachTopic.paramPath(
    coachSlug,
    formatStringAsUrlPath(topic)
  )
  return (
    <StyledContainer
      as={Link}
      to={destination}
      onClick={(event: any) => {
        if (!isAuthenticated || !isSubscribed) {
          event.preventDefault()
          setSignUpPrompt({
            destination,
            coachId,
            coachName,
            coachSlug,
            coachPrice,
          })
        }
      }}
    >
      <Thumbnail image={thumbnail} />
      <StyledInfo>
        <MediumHeading as="p">{topic}</MediumHeading>
        <StyledDetail loading={loading}>
          {topicVideos.length} videos
        </StyledDetail>
      </StyledInfo>
      <div>
        <OpenButton>
          <RightArrowIcon />
        </OpenButton>
      </div>
    </StyledContainer>
  )
}

export default TopicPreview
