import React, { useState } from "react"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { Link } from "gatsby"
import queryString from "querystring"
import { StyledContainer, StyledStepHeader } from "../InfoStep/InfoStep"
import LargeHeading from "../../../../ui/typography/LargeHeading/LargeHeading"
import ActionButton, {
  ActionButtonTheme,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import theme from "../../../../ui/theme"
import {
  getCoachPossessiveName,
  getCoachSlug,
  ICMSCoach,
} from "../../../../data/cms/coach"
import { ROUTES } from "../../../../navigation/routes"
import AppleIcon from "../../../../ui/icons/AppleIcon/AppleIcon"
import { formatDate } from "../../../AccountScreen/views/AccountSubscriptionsView/components/SubscribedCoach/components/SubscriptionAction"
import {
  getSignUpFlowCTADestination,
  getSignUpFlowCTAIsAppleButton,
  getSignUpFlowCTAIsVisible,
  getSignUpFlowCTALabel,
  ICMSSignUpFlow,
} from "../../../../data/cms/signUpFlow"
import { StyledAppleButton } from "../../../CoachSignUpScreen/components/DarkSection/DarkSection"

const StyledHeading = styled(LargeHeading)`
  margin-bottom: 16px;
`

const StyledSecondHeading = styled(LargeHeading)`
  color: ${theme.colors.primary};
  margin-bottom: 24px;
`

const StyledSection = styled.section`
  margin-top: 27.5px;
`

const StyledOptions = styled.div`
  > div {
    margin-top: 16px;
  }
`

const getDateParam = (): string => {
  if (typeof window === `undefined`) {
    return ""
  }
  const parsed = queryString.parse(window.location.search)
  let paramDate = ""
  if (parsed.d) {
    paramDate = parsed.d.toString()
  } else if (parsed["?d"]) {
    paramDate = parsed["?d"].toString()
  }
  return paramDate
}

interface Props extends RouteComponentProps {
  coach: ICMSCoach
  content: ICMSSignUpFlow
}

const CompletedStep: React.FC<Props> = ({ coach, content }) => {
  const [nextBillingDate] = useState(getDateParam())
  return (
    <StyledContainer>
      <StyledStepHeader>Done!</StyledStepHeader>
      <section>
        <StyledHeading as="h2">
          You're all signed up <br />
          and ready to go!
        </StyledHeading>
        <div>
          Your monthly subscription will be renewed on{" "}
          {formatDate(nextBillingDate)}.
        </div>
        <StyledSection>
          <StyledSecondHeading as="h3">
            Start your coaching experience.
          </StyledSecondHeading>
          <StyledOptions>
            <div>
              Download the iPhone app and login with your email and password for
              the full live experience.
            </div>
            <div>
              <ActionButton
                theme={ActionButtonTheme.WHITE}
                href="https://apps.apple.com/us/app/id1514317648"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppleIcon />
                Download for iPhone
              </ActionButton>
            </div>
            <div>
              Don't have an iPhone? No worries, you can view your coach's
              profile online to access all their latest videos and topics.
            </div>
            <div>
              <ActionButton
                theme={ActionButtonTheme.SECONDARY}
                as={Link}
                to={ROUTES.coach.paramPath(getCoachSlug(coach))}
              >
                View {getCoachPossessiveName(coach)} Profile
              </ActionButton>
            </div>
          </StyledOptions>
        </StyledSection>
      </section>
    </StyledContainer>
  )
}

export default CompletedStep
