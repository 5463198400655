import React from "react"
import styled from "styled-components"
import TopicPreview from "./components/TopicPreview/TopicPreview"
import { getTopicVideos, IGatsbyVideo } from "../../../firebase/video"

const StyledContainer = styled.ul`
  margin-bottom: 50px;
  
  > li {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`

const TopicsList: React.FC<{
  coachId: string
  coachSlug: string
  coachName: string
  coachPrice: string
  topics: string[]
  videos: IGatsbyVideo[]
  loading?: boolean
  isSubscribed: boolean
}> = ({
  coachId,
  coachSlug,
  coachPrice,
  coachName,
  topics,
  videos,
  loading = false,
  isSubscribed,
}) => (
  <StyledContainer>
    {topics.map(topic => {
      return (
        <li key={topic}>
          <TopicPreview
            coachId={coachId}
            coachSlug={coachSlug}
            coachName={coachName}
            coachPrice={coachPrice}
            topic={topic}
            topicVideos={getTopicVideos(topic, videos)}
            loading={loading}
            isSubscribed={isSubscribed}
          />
        </li>
      )
    })}
  </StyledContainer>
)

export default TopicsList
