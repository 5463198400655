import styled, { css } from "styled-components"
import theme from "./theme"
import { DESKTOP_BREAKPOINT } from "./responsive"

export const responsiveScrollableContainerDesktopLeftPaddingCss = css`
  padding-left: calc(
    (100vw - ${theme.dimensions.desktopContentMaxWidth}px) / 2
  );

  @media (max-width: ${theme.dimensions.desktopContentMaxWidth + 20}px) {
    padding-left: 10px;
  }
`

export const responsiveScrollableContainerDesktopRightPaddingCss = css`
  padding-right: calc(
    (100vw - ${theme.dimensions.desktopContentMaxWidth}px) / 2
  );

  @media (max-width: ${theme.dimensions.desktopContentMaxWidth + 20}px) {
    padding-right: 10px;
  }
`

export const responsiveScrollableContainerCss = css`
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: -16px;
  margin-bottom: -16px;

  > li {
    &:first-child {
      padding-left: 27px;
      padding-left: calc(
        (100vw - ${theme.dimensions.mobileContentMaxWidth}px) / 2
      );

      @media (max-width: ${theme.dimensions.mobileContentMaxWidth + 20}px) {
        padding-left: 10px;
      }

      ${DESKTOP_BREAKPOINT} {
        ${responsiveScrollableContainerDesktopLeftPaddingCss};
      }
    }

    &:last-child {
      padding-right: 27px;
      padding-right: calc(
        (100vw - ${theme.dimensions.mobileContentMaxWidth}px) / 2
      );

      @media (max-width: ${theme.dimensions.mobileContentMaxWidth + 20}px) {
        padding-right: 10px;
      }

      ${DESKTOP_BREAKPOINT} {
        ${responsiveScrollableContainerDesktopRightPaddingCss};
      }
    }
  }
`

export const ResponsiveScrollableContainer = styled.ul`
  ${responsiveScrollableContainerCss};
`
