import styled from "styled-components"
import theme from "../../theme"
import { bgImageCss } from "../../shared"

const Thumbnail = styled.div<{
  image: string
}>`
  ${bgImageCss};
  width: 64px;
  height: 64px;
  border-radius: ${theme.borderRadius.common}px;
  background-image: url(${props => props.image});
`

export default Thumbnail
