import React from "react"
import styled from "styled-components"
import { StyledContainer, StyledStepHeader } from "../../../InfoStep/InfoStep"
import { StyledHeading } from "../../PaymentStep"
import ActionButton, {
  ActionButtonTheme,
} from "../../../../../../ui/buttons/ActionButton/ActionButton"
import LeftArrow from "../../../../../../assets/inline-assets/arrowLeft.svg"
import { StyledBackButton } from "../../../CreateStep/CreateStep"
import { useHelpUrl } from "../../../../../../components/ForgotDetailsModal/ForgotDetailsModal"

const StyledContent = styled.div`
  margin-top: 120px;
`

const StyledDescription = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-top: 13px;
  margin-bottom: 132px;
  max-width: 342px;
  button,
  a {
    text-decoration: underline;
    font: inherit;
    background: none;
    color: inherit;
    border: 0;
    padding: 0;
    margin: 0;
  }
`

const PaymentErrorView: React.FC<{
  clearError: () => void
  helpUrl: string
}> = ({ clearError, helpUrl }) => {
  return (
    <StyledContainer>
      <StyledStepHeader>
        <StyledBackButton onClick={clearError}>
          <LeftArrow />
        </StyledBackButton>
        {/* <div>Step Three</div> */}
      </StyledStepHeader>
      <StyledContent>
        <StyledHeading>Payment error.</StyledHeading>
        <StyledDescription>
          There was an error with the payment process. View our{" "}
          <a href={useHelpUrl()} target="_blank" rel="noopener noreferrer">
            help page
          </a>{" "}
          or{" "}
          <button type="button" onClick={clearError}>
            go back
          </button>
          .
        </StyledDescription>
        {/* <StyledDescription> */}
        {/*  There was an error with the payment process.{" "} */}
        {/*  <a onClick={clearError}>Go back</a>. */}
        {/* </StyledDescription> */}
        <div>
          <ActionButton
            theme={ActionButtonTheme.SECONDARY}
            onClick={clearError}
          >
            Back
          </ActionButton>
        </div>
      </StyledContent>
    </StyledContainer>
  )
}

export default PaymentErrorView
