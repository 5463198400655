import React from "react"
import styled from "styled-components"
import theme from "../../theme"

const StyledAvatar = styled.div<{
  image: string
}>`
  background: ${theme.colors.secondary};
  width: ${theme.dimensions.mediumAvatar}px;
  height: ${theme.dimensions.mediumAvatar}px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});
`

const Avatar: React.FC<{
  image: string
}> = props => <StyledAvatar {...props} />

export default Avatar
