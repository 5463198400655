import React from "react"
import styled from "styled-components"
import theme from "../../../../../../../../ui/theme"
import Checkbox from "../../../../../../../../ui/inputs/Checkbox/Checkbox"
import { ROUTES } from "../../../../../../../../navigation/routes"

export const StyledTermsWrapper = styled.div`
  margin: ${theme.spacing.mediumPlusLess}px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const StyledTermsLabel = styled.label`
  display: flex;
  align-items: center;

  a {
    text-decoration: underline;
  }

  span {
    display: inline-block;
    margin-left: 13.35px;
  }
`

const TermsAndConditionsCheck: React.FC<{
  checked: boolean
  onChange: (arg0: any) => void
  onBlur: (arg0: any) => void
}> = ({ checked, onChange, onBlur }) => (
  <StyledTermsWrapper>
    <StyledTermsLabel>
      <Checkbox
        id="terms"
        onChange={onChange}
        onBlur={onBlur}
        checked={checked}
      />
      <span>
        I accept the {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={ROUTES.terms.path} target="_blank">
          Terms and Conditions
        </a>
      </span>
    </StyledTermsLabel>
  </StyledTermsWrapper>
)

export default TermsAndConditionsCheck
