import React from "react"
import styled from "styled-components"
import theme from "../../theme"
import Tick from "../../../assets/inline-assets/tick.svg"

const StyledInput = styled.input<any>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:focus {
    outline: none;
  }
`

const StyledCheckbox = styled.div<{
  checked: boolean | undefined
}>`
  width: 19px;
  height: 19px;
  border: 2px solid ${theme.colors.primary};
  border-radius: 2.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    position: relative;
    top: -1px;
    left: -0.5px;
    visibility: ${props => (props.checked ? "visible" : "hidden")};

    path {
      stroke: ${theme.colors.primary};
    }
  }
`

type Props = React.HTMLProps<HTMLInputElement>

const Checkbox: React.FC<Props> = ({ checked, ...props }) => (
  <div>
    <StyledInput checked={checked} type="checkbox" {...props} />
    <StyledCheckbox checked={checked}>
      <Tick />
    </StyledCheckbox>
  </div>
)

export default Checkbox
