import * as Yup from "yup"

export const useEmailSignUpFormSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .min(8)
      .required(),
    email: Yup.string()
      .email()
      .required(),
    terms: Yup.bool().oneOf([true]),
  })
}
