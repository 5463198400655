import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import ActionButton, {
  ActionButtonTheme,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import { DesktopMaxWidthContent } from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import { ROUTES } from "../../../../navigation/routes"
import { fancyWideFontCss } from "../../../../ui/typography/fonts"
import { addCoachToShortlist } from "../../../../firebase/user"
import StarIcon from "../../../../assets/inline-assets/star.svg"
import { useIsUserAuthenticated } from "../../../../auth/components/AuthHandler/AuthHandler"
import { retinaMediaQuery } from "../../../../ui/helpers"
import { BackIcon } from "../../../AccountScreen/views/AccountSettingsView/AccountSettingsView"
import PriceText from "../../../../ui/components/PriceText/PriceText"
import { DESKTOP_BREAKPOINT } from "../../../../ui/responsive"

const StyledContainer = styled.section`
  background: url('/svgs/red-box.svg') center right no-repeat #EFEFEF;
  color: ${theme.colors.darkGrey};
`

const StyledContent = styled(DesktopMaxWidthContent)`
  //display: flex;
  display: grid;
  grid-column-gap: 73px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  position: relative;
  min-height: 607px;
`

const StyledImageContainer = styled.div<{
  image: string
  image2x: string
}>`
  grid-column: span 2;
  background-image: url(${props => props.image});
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  margin-bottom: -42px;
  border-radius: 0 0 24px 24px;
  
  ${retinaMediaQuery} {
    background-image: url("${props => props.image2x}");
  }
`

const StyledInfoContainer = styled.div`
  grid-column: span 3;
  width: calc(100%);
  padding-left: 24px;
  display: flex;
  align-items: flex-end;
`

const StyledInfo = styled.div`
  width: 100%;
  align-self: center;
  padding-right: 72px;
`

const columnLayoutCss = css`
  display: flex;
  align-items: center;
  width: 100%;

  > a,
  > button {
    &:first-child:not(:last-child) {
      width: auto;
      padding-left: 75px;
      padding-right: 75px;
    }

    &:last-child:not(:first-child) {
      flex: 1;
      margin-left: 27px;
    }
  }
`

const rowLayoutCss = css`
  > a,
  > button {
    display: block;
    width: 100%;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const StyledOptions = styled.div<{
  columnLayout: boolean
}>`
  ${props => (props.columnLayout ? columnLayoutCss : rowLayoutCss)};
  align-items: flex-start;
`

const StyledColumnOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  > a,
  > button {
    flex: 1;

    &:first-child:not(:last-child) {
      margin-right: 8px;
    }

    &:last-child:not(:first-child) {
      margin-left: 8px;
    }
  }
`

const StyledLargeHeading = styled.h1`
  ${fancyWideFontCss};
  font-size: ${theme.fontSizes.giant}px;
  line-height: ${theme.lineHeights.giant}px;
  font-weight: 900;
  text-transform: uppercase;
  margin-left: -8px;
`

const StyledTags = styled(MediumPlusHeading)`
  //margin-top: -5px;
  margin-top: 5px;
`

const StyledBio = styled.div`
  margin-top: 23px;
  margin-bottom: 32px;
`

export const StyledBackButton = styled.button`
  display: inline-flex;
  padding: 4px 20px 4px 12px;
  position: absolute;
  align-items: center;
  color: ${theme.colors.white};
  background: #404040;
  font-weight: ${theme.fontWeights.bold};
  border-radius: ${theme.borderRadius.large}px;
  font-size: ${theme.fontSizes.smallish}px;
  top: 32px;
  left: 40px;
  z-index: 10;
  
  svg {
    margin-right: 6px;
    path {
      stroke: ${theme.colors.lightGrey};
    }
  }
`

const StyledStarButtonContent = styled.div<{
  light: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    path {
      stroke: ${props => (props.light ? theme.colors.white : "#b3b1de")};
    }
  }
`

export const SignUpWrapper = styled.div<{
  isMobile?: boolean
}>`
  flex: 1;
  
  &:not(:first-child) {
    margin-left: ${props => (props.isMobile ? "0" : "27")}px;
  }

  p {
    margin-top: ${props => (props.isMobile ? "0" : "12")}px;
    font-size: ${props =>
      props.isMobile ? theme.fontSizes.smallish : theme.fontSizes.tiny}px;
    text-align: center;
    color: ${props =>
      props.isMobile ? theme.colors.black : theme.colors.darkGrey};
  }
`

export const AddToShortlistButton: React.FC<{
  coachId: string
  theme?: ActionButtonTheme
}> = ({ coachId, theme: buttonTheme = ActionButtonTheme.SECONDARY }) => (
  <ActionButton theme={buttonTheme}>
    <StyledStarButtonContent
      onClick={() => {
        addCoachToShortlist(coachId)
      }}
      light={buttonTheme === ActionButtonTheme.DEFAULT}
    >
      <StarIcon />
      Add to Shortlist
    </StyledStarButtonContent>
  </ActionButton>
)

const DesktopIntroSection: React.FC<{
  name: string
  tags: string
  bio: string
  coachId: string
  coachSlug: string
  isSubscribed: boolean
  subscriptionPrice: string
  introImage: [string, string]
  isPlaceholder: boolean
  hasValidTrailer: boolean
  applyToCoachUrl: string
  openModal: () => void
  openVideoModal: () => void
}> = ({
  name,
  tags,
  bio,
  coachId,
  coachSlug,
  isSubscribed,
  subscriptionPrice,
  introImage,
  isPlaceholder,
  applyToCoachUrl,
  openModal,
  openVideoModal,
  hasValidTrailer
}) => {
  const authenticated = useIsUserAuthenticated()

  return (
    <>
      <StyledContainer>
        <StyledContent>
          <StyledBackButton as={Link} to={ROUTES.discover.path}>
            <BackIcon /> Back
          </StyledBackButton>
          <StyledImageContainer image={introImage[0]} image2x={introImage[1]} />
          <StyledInfoContainer>
            <StyledInfo>
              <StyledLargeHeading>{name}</StyledLargeHeading>
              <StyledTags as="h3">{tags}</StyledTags>
              <StyledBio>
                <p>{bio}</p>
              </StyledBio>
              <StyledOptions columnLayout>
                {/* eslint-disable-next-line no-nested-ternary */}
                {isPlaceholder ? (
                  <ActionButton
                    theme={ActionButtonTheme.DARK}
                    onClick={openModal}
                  >
                    Notify me on launch
                  </ActionButton>
                ) : hasValidTrailer ? (
                  <ActionButton
                    theme={ActionButtonTheme.DARK_OUTLINE}
                    onClick={openVideoModal}
                  >
                    Play Trailer
                  </ActionButton>
                ) : null}
                {!isSubscribed && !isPlaceholder && (
                  <SignUpWrapper>
                    <ActionButton
                      as={Link}
                      to={
                        authenticated
                          ? ROUTES.coachSignupBilling.paramPath(
                              coachSlug,
                              ROUTES.coach.paramPath(coachSlug)
                            )
                          : ROUTES.coachSignup.paramPath(
                              coachSlug,
                              ROUTES.coach.paramPath(coachSlug)
                            )
                      }
                    >
                      <PriceText
                        price={Number(subscriptionPrice)}
                        prefix="Subscribe - $"
                        suffix=" USD / month"
                      />
                    </ActionButton>
                    <p>*GST will be added for Aus residents</p>
                  </SignUpWrapper>
                )}
                {isSubscribed && !isPlaceholder && (
                  <ActionButton as={Link} to={ROUTES.accountSubscriptions.path}>
                    Go To My Coaches
                  </ActionButton>
                )}
                {!isSubscribed && isPlaceholder && (
                  <ActionButton
                    theme={ActionButtonTheme.DARK_OUTLINE}
                    as="a"
                    href={applyToCoachUrl}
                    target="_blank"
                  >
                    Apply to coach
                  </ActionButton>
                )}
              </StyledOptions>
            </StyledInfo>
          </StyledInfoContainer>
        </StyledContent>
      </StyledContainer>
    </>
  )
}

export default DesktopIntroSection
