import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { navigate, Link } from "gatsby"
import styled from "styled-components"
import CurrencyFormat from "react-currency-format"
import { StyledContainer, StyledStepHeader } from "../InfoStep/InfoStep"
import { ROUTES } from "../../../../navigation/routes"
import { useIsUserAuthenticated } from "../../../../auth/components/AuthHandler/AuthHandler"
import {
  useSignUpBasePath,
  useSignUpContext,
} from "../../components/SignUpContextHandler/SignUpContextHandler"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import {
  getCoachName,
  getCoachSlug,
  ICMSCoach,
} from "../../../../data/cms/coach"
import BillingForm from "./components/BillingForm/BillingForm"
import { StyledBackButton } from "../PaymentStep/PaymentStep"
import { getFirestoreOfferingStripePriceAmount } from "../../../../firebase/offerings"
import { BackIcon } from "../../../AccountScreen/views/AccountSettingsView/AccountSettingsView"
import BillingErrorView from "./components/BillingErrorView/BillingErrorVIew"
import {
  getSignUpFlowPaymentErrorHelpUrl,
  ICMSSignUpFlow,
} from "../../../../data/cms/signUpFlow"

export const StyledHeading = styled(MediumPlusHeading)`
  line-height: 30px;
`

const StyledDescription = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-top: 8px;
`

interface Props extends RouteComponentProps {
  coach: ICMSCoach
  content: ICMSSignUpFlow
}

const BillingStep: React.FC<Props> = ({ coach, content }) => {
  const authenticated = useIsUserAuthenticated()
  const basePath = useSignUpBasePath()
  const { coachOffering } = useSignUpContext()
  const [billingError, setBillingError] = useState<any>()
  useEffect(() => {
    if (!authenticated) {
      navigate(`${basePath}${ROUTES.signUp.subPath}`, {
        replace: true,
      })
    }
  }, [authenticated])
  if (billingError)
    return (
      <BillingErrorView
        clearError={() => {
          setBillingError(undefined)
        }}
        helpUrl={getSignUpFlowPaymentErrorHelpUrl(content)}
      />
    )
  return (
    <StyledContainer>
      <StyledBackButton
        as={Link}
        to={
          authenticated
            ? ROUTES.coach.paramPath(coach.slug)
            : ROUTES.coach.paramPath(coach.slug)
        }
      >
        <BackIcon />
      </StyledBackButton>
      <StyledStepHeader>{/* <div>Step Three</div> */}</StyledStepHeader>
      <div>
        <StyledHeading>Subscribe to {getCoachName(coach)}</StyledHeading>
        <StyledDescription>
          <CurrencyFormat
            value={getFirestoreOfferingStripePriceAmount(coachOffering) / 100}
            prefix="$"
            suffix=" USD / month"
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
          />
        </StyledDescription>
      </div>
      <BillingForm
        coachSlug={getCoachSlug(coach)}
        handleError={setBillingError}
      />
    </StyledContainer>
  )
}

export default BillingStep
