import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../theme"
import MediumHeading from "../../../../typography/MediumHeading/MediumHeading"
import {
  getFormattedVideoDuration,
  getVideoFormattedTimeAgo,
  getVideoThumbnail,
  getVideoTitle,
  getVideoVodLink,
  IGatsbyVideo,
} from "../../../../../firebase/video"
import { bgImageCss, placeholderBackgroundCss } from "../../../../shared"
import { DESKTOP_BREAKPOINT } from "../../../../responsive"

const compactCss = css`
  width: 100%;
  height: 200px;
  height: calc((50vw - 12px - 10px - ((100vw - 380px) / 2)) * 1.386);
`

const largeDesktopCss = css`
  ${DESKTOP_BREAKPOINT} {
    width: 283px;
    height: 423px;
  }
`

export const StyledContainer: any = styled.div<{
  largeDesktop: boolean
  compact: boolean
  thumbnail: string
  placeholder?: boolean
}>`
  ${bgImageCss};
  width: 227px;
  height: 360px;
  border-radius: ${theme.borderRadius.common}px;
  background-image: url(${props => props.thumbnail});
  position: relative;
  text-decoration: none;
  display: block;
  ${props => (props.compact ? compactCss : "")};
  ${props => (props.largeDesktop ? largeDesktopCss : "")};
  ${props => (props.placeholder ? placeholderBackgroundCss : "")};
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0;
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    border-radius: 0 0 8px 8px;
  }
`

const StyledDuration = styled.div`
  background-color: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
  position: absolute;
  top: 12px;
  right: 14.5px;
  border-radius: ${theme.borderRadius.small}px;
  font-weight: 600;
  font-size: ${theme.fontSizes.small}px;
  line-height: ${theme.lineHeights.smallish / theme.fontSizes.small};
  padding: 3px 5.5px;
`

const compactInfoCss = css`
  padding-left: 11px;
  padding-bottom: 3.5px;
`

const StyledInfo = styled.div<{
  compact: boolean
}>`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 23px;
  padding-bottom: 20px;
  padding-right: 14px;
  color: ${theme.colors.white};
  ${props => (props.compact ? compactInfoCss : "")};
`

const StyledDate = styled.div`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 20px;
  margin-top: 8px;
`

const StyledCompactHeading = styled(StyledDate)`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: 20px;
`

const SessionPreview: React.FC<{
  largeDesktop?: boolean
  compact?: boolean
  video: IGatsbyVideo
  onOpen?: (event: any) => void
}> = ({ largeDesktop = false, compact = false, video, onOpen }) => (
  <StyledContainer
    largeDesktop={largeDesktop}
    compact={compact}
    as={Link}
    to={getVideoVodLink(video)}
    thumbnail={getVideoThumbnail(video)}
    onClick={event => {
      if (onOpen) {
        onOpen(event)
      }
    }}
  >
    <StyledDuration>{getFormattedVideoDuration(video)}</StyledDuration>
    <StyledInfo compact={compact}>
      {compact ? (
        <StyledCompactHeading>{getVideoTitle(video)}</StyledCompactHeading>
      ) : (
        <>
          <MediumHeading as="p">{getVideoTitle(video)}</MediumHeading>
          <StyledDate>{getVideoFormattedTimeAgo(video)}</StyledDate>
        </>
      )}
    </StyledInfo>
  </StyledContainer>
)

export default SessionPreview
