import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import { Router } from "@reach/router"
import CoachScreen from "../../screens/CoachScreen/CoachScreen"
import {
  getCoachName,
  getCoachTopics,
  getCoachWebsiteMeta,
  ICMSCoach,
} from "../../data/cms/coach"
import CoachTopicScreen from "../../screens/CoachTopicScreen/CoachTopicScreen"
import { ROUTES } from "../../navigation/routes"
import { formatStringAsUrlPath } from "../../utils/string"
import UserCoachSignupScreen from "../../screens/UserCoachSignupScreen/UserCoachSignupScreen"
import { getEnvKey } from "../../utils/env"
import { ICMSSignUpFlow } from "../../data/cms/signUpFlow"
import SeoMetaTags from "../../components/SiteMeta/SeoMetaTags/SeoMetaTags"
import { getAccountScreenApplyToCoachUrl, getSeoProps, ICMSMetaSocial } from "../../data/cms/socialMeta"

interface Props {
  pageContext: {
    coachId: string
  }
  data: {
    datoCmsCoachPrd?: ICMSCoach
    datoCmsCoachUat?: ICMSCoach
    datoCmsCoachStg?: ICMSCoach
    datoCmsCoachDev?: ICMSCoach
    datoCmsWebMetaSocialDev?: ICMSMetaSocial
    datoCmsWebMetaSocialStg?: ICMSMetaSocial
    datoCmsWebMetaSocial?: ICMSMetaSocial
    datoCmsWebMetaSocialPrd?: ICMSMetaSocial
    datoCmsWebSignUpFlowDev?: ICMSSignUpFlow
    datoCmsWebSignUpFlowStg?: ICMSSignUpFlow
    datoCmsWebSignUpFlowUat?: ICMSSignUpFlow
    datoCmsWebSignUpFlowPrd?: ICMSSignUpFlow
  }
}

const CoachPage: React.FC<Props> = props => {
  const coachKey = getEnvKey({
    devKey: "datoCmsCoachDev",
    stgKey: "datoCmsCoachStg",
    uatKey: "datoCmsCoachUat",
    prdKey: "datoCmsCoachPrd",
    defaultKey: "datoCmsCoachStg",
  })
  const coach = get(props, `data.${coachKey}`)
  const coachId = get(props, "pageContext.coachId")
  const coachSlug = get(props, "pageContext.slug")
  if (!coach) {
    console.error("No coach data found")
    return null
  }
  if (!coachId) {
    console.error("No coach id found")
    return null
  }
  if (!coachSlug) {
    console.error("No coach slug found")
    return null
  }
  const contentKey = getEnvKey({
    devKey: "datoCmsWebSignUpFlowDev",
    stgKey: "datoCmsWebSignUpFlowStg",
    uatKey: "datoCmsWebSignUpFlowUat",
    prdKey: "datoCmsWebSignUpFlowPrd",
    defaultKey: "datoCmsWebSignUpFlowUat",
  })
  const content: ICMSSignUpFlow = get(props, `data.${contentKey}`)
  const meta = getCoachWebsiteMeta(coach)

  const webMetaKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })
  const data: ICMSMetaSocial = get(props, `data.${webMetaKey}`)
  const coachUrl = getAccountScreenApplyToCoachUrl(data)

  return (
    <>
      {meta && (
        <SeoMetaTags pageTitle={getCoachName(coach)} {...getSeoProps(meta)} />
      )}
      <Router basepath={ROUTES.coach.paramPath(coachSlug)}>
        <CoachScreen path="/" coach={coach} coachId={coachId} applyToCoachUrl={coachUrl} />
        <UserCoachSignupScreen
          coach={coach}
          coachId={coachId}
          path={ROUTES.coachSignup.subPath}
          content={content}
        />
        {getCoachTopics(coach).map(topic => (
          <CoachTopicScreen
            path={ROUTES.coachTopic.paramSubPath(formatStringAsUrlPath(topic))}
            topic={topic}
            coachId={coachId}
            coachSlug={coachSlug}
            key={topic}
          />
        ))}
      </Router>
    </>
  )
}

export const query = graphql`
  query CoachQuery(
    $coachId: String!
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsCoachUat(coachId: { eq: $coachId }) @include(if: $uatEnv) {
      ...UatCoach
    }
    datoCmsCoachPrd(coachId: { eq: $coachId }) @include(if: $prdEnv) {
      ...PrdCoach
    }
    datoCmsCoachStg(coachId: { eq: $coachId }) @include(if: $stgEnv) {
      ...Coach
    }
    datoCmsCoachDev(coachId: { eq: $coachId }) @include(if: $devEnv) {
      ...DevCoach
    }
    datoCmsWebSignUpFlowDev @include(if: $devEnv) {
      ...WebSignUpFlowDev
    }
    datoCmsWebSignUpFlowStg @include(if: $stgEnv) {
      ...WebSignUpFlowStg
    }
    datoCmsWebSignUpFlowUat @include(if: $uatEnv) {
      ...WebSignUpFlowUat
    }
    datoCmsWebSignUpFlowPrd @include(if: $prdEnv) {
      ...WebSignUpFlowPrd
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
        accountScreenApplyToCoachUrl
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
        accountScreenApplyToCoachUrl
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
        accountScreenApplyToCoachUrl
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
        accountScreenApplyToCoachUrl
    }
  }
`

export default CoachPage
