import axios from "axios"
import * as stripeJs from "@stripe/stripe-js"
import firebase from "firebase/app"

const API_BASE = process.env.GATSBY_GOOGLE_CLOUD_API_BASE
// const API_BASE = "https://us-central1-youspired-dev.cloudfunctions.net"

const defaultAxiosConfig = {
  baseURL: API_BASE,
}

export interface IFirestoreSubscription {
  amount: number
  avatarUrl: string
  billing_issues_detected_at: null
  coachId: string
  currency: string
  expires_date: string
  name: string
  period_type: string
  username: string
  subscriptionId: string
  slug: string
  cancelAtPeriodEnd: boolean
  canceledAt?: number
  status: "active" | string
  store: "stripe" | "app_store" | string
}

class FirebaseApiHandler {
  api = axios.create(defaultAxiosConfig)

  setAuthToken = (authToken: string) => {
    Object.assign(this.api.defaults, {
      headers: { authorization: `${authToken}` },
    })
  }

  refreshAuthToken = async (): Promise<any> => {
    const user = await firebase.auth().currentUser
    if (!user) {
      console.warn("no user")
      return Promise.reject()
    }
    const token = await user.getIdToken()
    if (!token) {
      console.warn("no token")
      return Promise.reject()
    }
    this.setAuthToken(token)
    return Promise.resolve()
  }

  checkForDuplicateUsername = (username: string): Promise<any> => {
    return this.refreshAuthToken().then(() => {
      return this.api.get(`/checkForDuplicateUsername?username=${username}`)
    })
  }

  getStripeCustomerPortal = (): Promise<{
    data: {
      customerPortalUrl: string
    }
  }> => {
    return this.refreshAuthToken().then(() => {
      return this.api.get(`/stripeCustomerPortal`).catch(error => {
        Sentry.captureMessage("Failed to fetch stripe customer portal url")
        Sentry.captureException(error)
        return Promise.reject(error)
      })
    })
  }

  addBillingAddress = (billingAddress: any): Promise<any> =>
    this.refreshAuthToken().then(() => {
      return this.api.post("/addAddress", billingAddress)
    })

  addPaymentInfo = (source: any): Promise<any> =>
    this.refreshAuthToken().then(() => {
      return this.api.post("/addPaymentInfo", {
        source,
      })
    })

  subscribeToCoach = (
    coachId: string
  ): Promise<{
    data: string
  }> =>
    this.refreshAuthToken().then(() => {
      return this.api.post("/subscribeToCoach", {
        coachId,
      })
    })

  unsubscribeFromCoach = (subscriptionId: string): Promise<any> =>
    this.refreshAuthToken().then(() => {
      return this.api.post("/unsubscribeFromCoach", {
        subscriptionId,
      })
    })

  resubscribeToCoach = (subscriptionId: string): Promise<any> =>
    this.refreshAuthToken().then(() => {
      return this.api.post("/resubscribeToCoach", {
        subscriptionId,
      })
    })

  getUserSubscriptions = (): Promise<{
    data: IFirestoreSubscription[]
  }> =>
    this.refreshAuthToken().then(() => {
      return this.api.get("/listUserSubscriptions")
    })
}

export const firebaseApiHandler = new FirebaseApiHandler()

export const createStripeSource = (
  stripe: stripeJs.Stripe,
  cardNumberElement: stripeJs.StripeCardNumberElement,
  fbUser: firebase.User,
  name: string
): Promise<stripeJs.Source> => {
  return stripe
    .createSource(cardNumberElement, {
      type: "card",
      metadata: {
        firebaseUserId: fbUser.uid,
      },
      owner: {
        name: name || fbUser.displayName || "",
      },
    })
    .then(result => {
      // console.log("result", result)
      if (result.error) {
        return Promise.reject(result.error)
      }
      if (!result.source) {
        return Promise.reject()
      }
      return result.source
    })
}

export const confirmPayment = async (
  stripe: stripeJs.Stripe,
  cardNumberElement: stripeJs.StripeCardNumberElement,
  fbUser: firebase.User,
  coachId: string,
  name: string
): Promise<string> => {
  let source
  try {
    source = await createStripeSource(stripe, cardNumberElement, fbUser, name)
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
  return firebaseApiHandler
    .addPaymentInfo(source.id)
    .then(() => {
      // console.log("response", response)
      return firebaseApiHandler.subscribeToCoach(coachId)
    })
    .then(response => {
      // console.log("response", response)
      return response.data
    })
}

// 176-314
